<template>
  <div
    :class="{
      'dark': darkMenu
    }"
  >
    <!-- Mobile logo -->
    <header 
      class="lg:hidden my-1 mx-2 flex z-10"
      :class="{
        'absolute top-0 inset-x-0': fixedHeader
      }"
    >
      <router-link to="/">
        <img
          class="dark:hidden"
          :src="imgixClient.buildURL('logos/an_logo.svg', {auto: 'format,compress', ch: 'DPR', q: 45, w: 114, fit: 'clip'})"
          alt="add:north 3D filaments"
          height="30"
          width="114"
          style="height: 30px; width: 114px;"
        />
        <img
          class="hidden dark:block"
          :src="imgixClient.buildURL('logos/an_logo_white.svg', {auto: 'format,compress', ch: 'DPR', q: 45, w: 114, fit: 'clip'})"
          alt="add:north 3D filaments"
          height="30"
          width="114"
          style="height: 30px; width: 114px;"
        />
      </router-link>
      <div class="flex-grow flex justify-end">
        <button
          class="rounded-full dark:text-white p-1 sm:p-2 focus:outline-none mr-2"
          @click="$store.commit('store/SET_SHOW_SHOP_SETTINGS', true)"
          :alt="$t('site.shopSettings')"
        >
          <svg
            class="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
          >
            <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
          </svg>
        </button>
      </div>
      <div class="flex items-center">
        <img :src="imgixClient.buildURL('other/sweden.png', {auto: 'format,compress', ch: 'DPR', q: 45, h: 40, fit: 'clip'})" alt="Made in Sweden" class="h-5" />
        <div class="text-xs font-extralight leading-none ml-1 text-gray-900 dark:text-white">
        Made in<br>Sweden
        </div>
      </div>
    </header>
    <nav class="fixed bottom-0 lg:hidden z-30 inset-x-0 bg-gray-800">
      <div class="grid grid-cols-5 text-white text-xxs uppercase font-medium w-full">
        <router-link
          class="flex flex-col items-center pt-2 text-white"
          to="/"
          style="height: 60px"
          exact-active-class="bg-gray-600"
        >
          <svg class="w-6 h-6 fill-current mb-1" viewBox="0 0 24 24">
            <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
          </svg>
          <div v-text="$t('common.home')" />
        </router-link>
        <router-link
          class="flex flex-col items-center pt-2 text-white"
          to="/browse"
          style="height: 60px"
          exact-active-class="bg-gray-600"
        >
          <svg class="w-6 h-6 fill-current mb-1" viewBox="0 0 24 24">
            <path d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z" />
          </svg>
          <div v-text="$t('common.shop')" />
        </router-link>
        <router-link
          class="flex flex-col items-center pt-2 text-white"
          to="/search"
          style="height: 60px"
          exact-active-class="bg-gray-600"
        >
          <svg class="w-6 h-6 fill-current mb-1" viewBox="0 0 24 24">
            <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
          </svg>
          <div v-text="$t('common.search')" />
        </router-link>
        <router-link
          class="flex flex-col items-center pt-2 text-white"
          to="/account"
          style="height: 60px"
          active-class="bg-gray-600"
        >
          <svg class="w-6 h-6 fill-current mb-1" viewBox="0 0 24 24">
            <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
          </svg>
          <div v-text="$t('common.account')" />
        </router-link>
        <router-link
          class="flex flex-col items-center pt-2 relative text-white"
          to="/cart"
          style="height: 60px"
          exact-active-class="bg-gray-600"
        >
          <svg class="w-6 h-6 fill-current mb-1" viewBox="0 0 24 24">
            <path d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z" />
          </svg>
          <div v-text="$t('common.cart')" />
          <div
            v-if="$store.getters['cart/itemsInCart'] > 0"
            class="absolute top-0 mt-1 mr-1 flex justify-center items-center"
          >
            <div
              class="ml-8 text-xs font-medium text-white bg-red-600 rounded-full"
              :class="$store.getters['cart/itemsInCart'] < 10 ? 'px-2' : 'px-1'"
              v-text="$store.getters['cart/itemsInCart']"
            />
          </div>
        </router-link>
      </div>
    </nav>
    <!-- Main header -->
    <header
      class="z-30 top-0 inset-x-0 hidden lg:block"
      :class="{
        'absolute': fixedHeader,
        'sticky': !fixedHeader,
      }"
    >
      <div class="container mx-auto p-2 xl:max-w-screen-lg xxl:max-w-screen-xl flex items-center align-center relative">
        <!-- LOGO -->
        <div
          class="mr-4 lg:mr-10 flex-shrink"
          style="max-width: 152px"
        >
          <router-link to="/">
            <img
              class="dark:hidden"
              :src="imgixClient.buildURL('logos/an_logo.svg', {auto: 'format,compress', ch: 'DPR', q: 45, w: 152, fit: 'clip'})"
              alt="add:north 3D filaments"
              style="width: 152px; height: 40px"
              width="152"
              height="40"
            />
            <img
              class="hidden dark:block"
              :src="imgixClient.buildURL('logos/an_logo_white.svg', {auto: 'format,compress', ch: 'DPR', q: 45, w: 152, fit: 'clip'})"
              alt="add:north 3D filaments"
              style="width: 152px; height: 40px"
              width="152"
              height="40"
            />
          </router-link>
        </div>
        <!-- MENU -->
        <HeaderProductsMenu />
        <!-- Knowledge link -->
        <div class="flex-shrink-0">
          <router-link
            class="flex-shrink-0 hover:text-gray-700 text-black dark:text-white dark:hover:text-gray-100 font-medium ml-3"
            to="/re-add"
            v-text="$t('meta.readd.title')"
          />
          <router-link
            class="flex-shrink-0 hover:text-gray-700 text-black dark:text-white dark:hover:text-gray-100 font-medium ml-6"
            to="/knowledge"
            v-text="$t('meta.knowledge.title')"
          />
        </div>
        <!-- Search BAR -->
        <Search />
        <!-- Right ICONS -->
        <div class="flex-grow-0 flex justify-end">
          
          <button
            class="rounded-full hover:bg-gray-200 dark:text-white dark:hover:bg-gray-500 p-1 sm:p-2 focus:outline-none"
            @click="$store.commit('store/SET_SHOW_SHOP_SETTINGS', true)"
            :alt="$t('site.shopSettings')"
          >
            <svg
              class="h-7 w-7 fill-current"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
          </button>
          <router-link
            to="/cart"
            class="relative rounded-full hover:bg-gray-200 dark:text-white dark:hover:bg-gray-500 p-2 md:p-3"
            :aria-label="$t('common.cart')"
          >
            <svg
              :alt="$t('common.cart')"
              class="h-6 w-6 fill-current focus:outline-none"
              viewBox="0 0 24 24"
            >
              <path d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z" />
            </svg>
            <div
              v-if="$store.getters['cart/itemsInCart'] > 0"
              class="absolute top-0 right-0 mt-1 mr-1 flex justify-center items-center"
            >
              <div
                class="text-xs font-medium text-white bg-red-600 rounded-full"
                :class="$store.getters['cart/itemsInCart'] < 10 ? 'px-2' : 'px-1'"
                v-text="$store.getters['cart/itemsInCart']"
              />
            </div>
          </router-link>
          <router-link
            class="rounded-full hover:bg-gray-200 dark:text-white dark:hover:bg-gray-500 p-1 sm:p-2 focus:outline-none"
            to="/account"
            :aria-label="$t('common.account')"
          >
            <svg :alt="$t('common.account')" class="h-8 w-8 fill-current" :class="$store.getters['account/authenticated'] ? 'text-green-300' : ''" viewBox="0 0 24 24"><path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>
          </router-link>
          <div class="flex items-center absolute inset-y-0 -right-20">
            <img :src="imgixClient.buildURL('other/sweden.png', {auto: 'format,compress', ch: 'DPR', q: 45, h: 40, fit: 'clip'})" alt="Made in Sweden" class="h-5" />
            <div class="text-xs font-extralight leading-none ml-1 text-gray-900 dark:text-white">
            Made in<br>Sweden
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import imgixClient from '../../services/imgixClient';
import Search from '../search/Search.vue';
import routes from '../../router/routes';
import HeaderProductsMenu from './HeaderProductsMenu.vue';

export default {
  name: 'Header',
  components: {
    Search,
    HeaderProductsMenu,
  },
  data() {
    return {
      imgixClient,
      language: process.env.VUE_APP_LANGUAGE,
      pages: routes.filter((i) => i.menu),
      fixedHeader: false,
      darkMenu: this.$router.currentRoute.meta.darkMenu,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.fixedHeader = !!to.meta.fixedHeader
        this.darkMenu = to.meta.darkMenu
      }
    },
  },
};
</script>
