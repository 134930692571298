var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"bg-footer dark:bg-gray-800 w-full pb-6"},[_c('Newsletter',{staticClass:"my-16",attrs:{"identifier":"footerSubscribe"}}),_c('div',{staticClass:"flex flex-col items-center gap-y-8 lg:gap-y-10 mb-10 px-4"},[_c('nav',{staticClass:"grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 lg:gap-x-10",attrs:{"role":"navigation"}},_vm._l((_vm.categories),function(pages,header){return _c('div',{key:header,staticClass:"flex md:justify-center"},[_c('div',[_c('p',{staticClass:"uppercase text-base font-bold text-gray-900 dark:text-white mb-2",domProps:{"textContent":_vm._s(_vm.$t(`pageCategories.${header}`))}}),_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((pages),function({ title, key, paths }){return _c('router-link',{key:paths.join(),staticClass:"block dark:text-gray-100 hover:text-gray-600 dark:hover:text-gray-200",attrs:{"to":`/${paths.join('/')}`},domProps:{"textContent":_vm._s(title ? title : _vm.$t(key))}})}),1)])])}),0),_c('div',{staticStyle:{"max-width":"320px"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"object-contain dark:hidden",staticStyle:{"width":"220px","height":"59px"},attrs:{"src":_vm.imgixClient.buildURL('logos/an_logo_black.svg', {
              auto: 'format,compress',
              ch: 'DPR',
              q: 45,
              w: 220,
              fit: 'clip',
            }),"alt":"add:north 3D filaments","width":"220","height":"59"}}),_c('img',{staticClass:"object-contain hidden dark:block",staticStyle:{"width":"220px","height":"59px"},attrs:{"src":_vm.imgixClient.buildURL('logos/an_logo_white.svg', {
              auto: 'format,compress',
              ch: 'DPR',
              q: 45,
              w: 220,
              fit: 'clip',
            }),"alt":"add:north 3D filaments","width":"220","height":"59"}})]),_c('div',{staticClass:"w-full text-sm mt-2 text-justify dark:text-white",domProps:{"textContent":_vm._s(_vm.$t('meta.about.description'))}}),_c('SocialMedia',{staticClass:"w-full"})],1)]),_c('div',{staticClass:"mb-64 w-full space-y-8 justify-center"},[_c('div',{staticClass:"elfsight-app-f21f8819-c2af-427e-a56e-056223e10caf"}),_vm._l((_vm.badges),function({ textKey, urls }){return _c('div',{key:textKey,staticClass:"flex items-center flex-col"},[_c('div',{staticClass:"font-medium mb-2 dark:text-white",domProps:{"textContent":_vm._s(_vm.$t(textKey))}}),_c('div',{staticClass:"mx-2 grid gap-2 sm:gap-4 md:gap-6 grid-flow-col",staticStyle:{"height":"30px"}},_vm._l((urls),function(url){return _c('img',{key:url,staticClass:"object-contain dark:bg-white",staticStyle:{"height":"30px"},attrs:{"src":_vm.imgixClient.buildURL(url, {
              auto: 'format,compress',
              ch: 'DPR',
              q: 45,
              h: 30,
              fit: 'clip',
            }),"height":"30","width":"90","alt":url.split('/')[1]}})}),0)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }