<template>
  <div
    class="flex justify-center align-center items-center w-full dark:text-white"
    style="min-height: calc(60vh)"
  >
    <div class="flex flex-wrap align-center items-center justify-center">
      <div>
        <h3 class="font-medium" v-text="`${$t('site.header404')}...`" />
        <h4 class="font-light mb-4 mt-2" v-text="$t('site.text404')" />
        <button
          class="main-button mr-4"
          @click="$router.go(-1)"
          v-text="$t('common.back')"
        />
        <router-link
          class="main-button outline mr-4"
          to="/"
          v-text="$t('common.home')"
        />
      </div>
      <div class="hidden md:block align-middle fill-current">
        <svg class="w-32 h-32" viewBox="0 0 24 24"><path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" /></svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
};
</script>
