<template>
  <div>
    <home-video />
    <div class="border-b pb-12 mt-8 mb-12">
      <section class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <Featured class="mx-6 xl:mx-0" />
      </section>
    </div>
    <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
      <div class="lg:flex justify-between items-start" style="min-height: 224px">
        <reviews />
        <home-add-club class="flex-shrink-0" />
      </div>
    </div>
    <div class="border-t border-b pt-8 pb-12 my-12">
      <div class="xl:max-w-screen-lg xxl:max-w-screen-xl w-full mx-auto">
        <best-sellers />
      </div>
    </div>
    <!-- <div class="pb-8">
      <div class="xl:max-w-screen-lg xxl:max-w-screen-xl w-full mx-auto">
        <PopularMaterials class="mx-6 xl:mx-0" />
      </div>
    </div> -->
    <knowledge-base-featured />
  </div>
</template>
<script>
// import PopularMater../components/home/KnowledgeBaseFeatured.vueopularMaterials.vue';
import Featured from '../components/home/Featured.vue';
import BestSellers from '../components/home/BestSellers.vue';
import KnowledgeBaseFeatured from '../components/home/KnowledgeBaseFeatured.vue';
import HomeAddClub from '../components/home/HomeAddClub.vue';
import HomeVideo from '../components/home/HomeVideo.vue';
import Reviews from '../components/shared/Reviews.vue';

export default {
  name: 'Home',
  components: {
    // PopularMaterials,
    Featured,
    BestSellers,
    KnowledgeBaseFeatured,
    HomeAddClub,
    HomeVideo,
    Reviews
  },
};
</script>