<template>
  <section class="px-4">
    <div class="mx-auto max-w-lg">
      <div class="mb-4">
        <h3
          v-text="$t('newsletter.stayUpdated')"
          class="font-medium text-gray-800 dark:text-white"
        />
        <p
          v-text="$t('newsletter.signUp')"
          class="max-w-md text-md sm:text-lg font-light text-gray-700 dark:text-gray-200"
        />
      </div>
      <form @submit="checkForm" method="post" novalidate="true" class="inline-block">
        <div class="styled-form relative">
          <input
            :id="identifier"
            name="subscribe"
            class="w-64 rounded-lg"
            type="email"
            placeholder=" "
            v-model="email"
          />
          <label :for="identifier" class="floating-label" v-text="$t('common.email')" />
          <div
            class="absolute inset-0"
            :class="{
              spinner: subscribing,
              hidden: !subscribing,
            }"
          />
          <button
            type="submit"
            class="absolute right-0 top-0 mr-2 bottom-0 font-bold rounded focus:outline-none dark:text-white"
            :disabled="subscribing"
            :aria-label="$t('newsletter.subscribe')"
          >
            <svg class="fill-current" height="28" width="28" viewBox="0 0 24 24">
              <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
            </svg>
          </button>
        </div>
        <div v-if="subscribeError" v-text="subscribeError" class="mx-2 mt-1 text-red-400" />
        <div v-if="subscribeSuccess" v-text="subscribeSuccess" class="mt-1 text-green-400" />
      </form>
    </div>
  </section>
</template>
<script>
export default {
  name: "Newsletter",
  props: {
    identifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      subscribing: false,
      subscribeError: "",
      subscribeSuccess: "",
    };
  },
  methods: {
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(e) {
      this.subscribeSuccess = "";
      this.subscribeError = "";
      if (!this.email) {
        this.subscribeError = this.$t("formValidation.emailRequired");
      } else if (!this.validEmail(this.email)) {
        this.subscribeError = this.$t("formValidation.invalidEmail");
      } else {
        this.subscribe();
      }
      return e.preventDefault();
    },
    subscribe() {
      this.subscribing = true;
      this.$api2
        .post("sendgrid/newsletter", { email: this.email })
        .then(() => {
          this.subscribeSuccess = this.$t("newsletter.nowSubscribed");
        })
        .catch(() => {
          this.subscribeError = this.$t("errors.unknownError");
        })
        .finally(() => {
          this.subscribing = false;
        });
    },
  },
};
</script>
