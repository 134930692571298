<template>
  <div class="min-h-screen flex flex-col relative">
    <ShopSettings />
    <Header />
    <main v-if="!$router.currentRoute.meta.noContainer" class="relative flex-grow flex overflow-hidden">
      <div class="inset-0 absolute flex-col hidden md:flex dark:opacity-50 dark:bg-opacity-50">
        <img
          :src="footerGrid"
          alt="3D grid"
          class="w-full absolute inset-x bottom-0 z-0"
          style="margin-bottom: -3px; height: calc(7.185vw)"
        />
        <div
          class="w-full absolute inset-0 bg z-0 dark:opacity-25"
          :style="`background-image: url(${backgroundGrid});`"
        />
      </div>
      <router-view
        class="container mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl py-0 z-10"
        role="main"
      />
    </main>
    <main v-else class="flex-grow flex overflow-hidden">
      <router-view
        class="w-full z-10"
        role="main"
      />
    </main>
    <div class="elfsight-app-c39f8e80-def0-4b1f-9939-1f8f744e0066" />
    <Footer />
    <Messages />
  </div>
</template>
<script>
import Header from '../components/shared/Header.vue';
import Messages from '../components/shared/Messages.vue';
import ShopSettings from '../components/shared/ShopSettings.vue';
import Footer from '../components/shared/Footer.vue';
import imgixClient from '@/services/imgixClient';

export default {
  name: 'app',
  components: {
    ShopSettings,
    Header,
    Footer,
    Messages,
  },
  data() {
    return {
      footerGrid: imgixClient.buildURL('backgrounds/footergrid.svg', { auto: 'format,compress', ch: 'Width,DPR,Save-Data' }),
      backgroundGrid: imgixClient.buildURL('backgrounds/backgroundgrid.svg', { auto: 'format,compress', ch: 'Width,DPR,Save-Data' }),
    };
  },
  computed: {
    country() {
      return this.$store.getters['store/country'];
    },
    dark() {
      return this.$store.state.shared.dark
    }
  },
  mounted() {
    if (!document.getElementById('elfsight')) {
      const script = document.createElement('script');
      script.src = 'https://apps.elfsight.com/p/platform.js';
      script.defer = true;
      script.id = 'elfsight';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  },
  watch: {
    dark: {
      immediate: true,
      handler(dark) {
        if (dark) {
          document.body.classList.add('dark')
        } else {
          document.body.classList.remove('dark')
        }
      }
    }
  },
  beforeDestroy() {
    document.body.classList.remove('dark')
  }
};
</script>
<style scoped>
.bg {
  background-size: 100% auto;
  background-repeat: repeat-y 0 0 round;
}
</style>
