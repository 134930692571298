<template>
  <nav v-click-outside="closeMenu" role="navigation">
    <div class="flex flex-grow">
      <div class="static">
        <button
          class="outline-none focus:outline-none p-2 inline-flex items-center hover:text-gray-700 dark:hover:text-gray-100 dark:text-white font-medium w-full"
          @click="toggleMenu"
        >
          <span v-text="$tc('store.products.product', 2)" />
          <svg
            class="w-4 h-4 ml-1 transform stroke-current"
            :class="{
              'rotate-180': menuOpen,
            }"
            viewBox="0 0 20 20"
          >
            <polyline fill="none" stroke-width="1.03" points="16 7 10 13 4 7" />
          </svg>
        </button>
        <!-- Category Menu -->
        <nav
          v-if="menuOpen"
          class="w-11/12 xxl:w-10/12 xxxl:w-9/12 z-20 absolute md:left-50 md:transform md:-translate-x-1/2 md:top-100 bg-white dark:bg-gray-800 shadow-lg rounded-b-lg"
        >
          <div class="flex flex-row py-8">
            <div class="flex-shrink-0 relative">
              <router-link
                class="block outline-none focus:outline-none items-center font-medium py-2 pr-6 pl-8 dark:text-white hover:text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
                :to="fixed[0].link"
                @click.native="closeMenu"
                v-text="fixed[0].text"
              />
              <button
                v-for="{ label } in categories"
                :key="label"
                class="flex w-full items-center outline-none focus:outline-none py-2 pr-6 pl-8 dark:text-white hover:text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
                :class="{
                  'bg-gray-200 dark:bg-gray-700': categorySelected === label,
                }"
                @click="categorySelected = label"
              >
                <div v-text="label" class="mr-6 font-medium" />
                <svg
                  class="right-1 absolute w-4 h-4 ml-1 transform -rotate-90 fill-current"
                  viewBox="0 0 20 20"
                >
                  <polyline stroke-width="1.03" points="16 7 10 13 4 7" />
                </svg>
              </button>
              <div class="md:my-6" />
              <router-link
                v-for="{ text, link } in fixed.slice(1)"
                :key="text"
                class="block outline-none focus:outline-none items-center font-medium py-2 pr-6 pl-8 dark:text-white hover:text-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600"
                :to="link"
                @click.native="closeMenu"
                v-text="text"
              />
            </div>
            <div class="pl-8 pr-12 border-l flex-shrink overflow-hidden">
              <div v-for="{ label, children } in categories" :key="label">
                <div
                  class="h-auto inline-block w-auto"
                  :class="{
                    hidden: categorySelected !== label,
                  }"
                >
                  <div
                    v-if="children"
                    class="w-full"
                    style="column-count: 3; column-gap: 5px; column-fill: balance"
                  >
                    <div
                      v-for="{ label: subLabel, path: subPath, children: subChildren } in children"
                      :key="subLabel"
                      class="mb-6 w-full inline-block"
                    >
                      <router-link
                        class="w-full block text-left relative outline-none focus:outline-none font-medium text-gray-700 dark:text-white hover:text-gray-400 dark:hover:text-gray-200"
                        :to="$store.getters['shared/encodeUrl'](`/shop/${subPath}`)"
                        v-text="subLabel"
                        @click.native="closeMenu"
                      />
                      <div v-if="subChildren" class="h-auto inline-block w-auto">
                        <div
                          v-for="{ label: subSubLabel, path: subSubPath } in subChildren"
                          :key="subSubLabel"
                        >
                          <router-link
                            class="w-full block text-left relative outline-none focus:outline-none text-gray-600 dark:text-gray-300 mb-2 hover:text-gray-400 dark:hover:text-gray-400"
                            :to="$store.getters['shared/encodeUrl'](`/shop/${subSubPath}`)"
                            v-text="subSubLabel"
                            @click.native="closeMenu"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </nav>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "Header",
  directives: {
    ClickOutside,
  },
  data() {
    return {
      language: process.env.VUE_APP_LANGUAGE,
      menuOpen: false,
      categorySelected: null,
      fixed: [
        {
          text: this.$t("store.products.allProducts"),
          link: this.$store.getters["shared/encodeUrl"]("/shop"),
        },
        {
          text: this.$t("store.products.latestProducts"),
          link: this.$store.getters["shared/encodeUrl"]("/shop"),
        },
        {
          text: this.$tc("store.products.bestSeller", 2),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { sort: "bestSellers" }),
        },
        {
          text: this.$tc("store.products.staffPick", 2),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { sort: "staffPicks" }),
        },
        {
          text: this.$t("common.onSale"),
          link: this.$store.getters["shared/encodeUrl"]("/shop", { toggle: "onSale" }),
        },
      ],
    };
  },
  computed: {
    categories() {
      return this.$store.getters["content/siteProductCategories"];
    },
  },
  methods: {
    toggleMenu() {
      if (this.menuOpen) return this.closeMenu();
      this.openMenu();
    },
    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
  watch: {
    categories: {
      immediate: true,
      handler(categories) {
        if (categories.length && !this.categorySelected)
          this.categorySelected = categories[0].label;
      },
    },
    $route: {
      immediate: true,
      handler(to) {
        this.closeMenu();
      },
    },
  },
};
</script>
