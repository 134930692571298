<template>
  <router-link
    class="shadow-sm hover:bg-gray-100 border dark:bg-gray-700 dark:hover:bg-gray-600 w-full flex"
    style="height: 160px"
    :to="`/knowledge/articles/${article.slug}`"
  >
    <div class="dark:bg-white h-full ml-2 rounded-l-md flex-shrink-0" style="width: 70px">
      <img
        :src="image(article.cover.src)"
        :alt="article.cover.alt"
        class="object-contain block w-full h-full"
      />
    </div>
    <div class="h-full flex flex-col overflow-hidden p-2">
      <div class="text-sm flex-shrink-0 font-medium uppercase" v-html="article.type" />
      <h5 class="font-medium flex-shrink-0 truncate" v-html="article.title" />
      <h6 class="text-xs flex-grow flex-shrink overflow-ellipsis overflow-hidden w-full" style="max-height: 54px" v-html="article.description" />
      <div class="flex flex-shrink-0 mt-1">
        <div
          v-for="tag in article.tags"
          :key="`${article.slug}${tag}`"
          class="text-xs font-medium uppercase rounded-lg px-2 py-1 m-1 bg-gray-200 dark:bg-gray-500"
          v-html="`#${tag}`"
        />
      </div>
    </div>
  </router-link>
</template>
<script>
import imgixClient from '../../services/imgixClient';

export default {
  name: 'KnowledgeCardSearch',
  props: {
    article: {
      type: Object,
      required: true
    },
  },
  methods: {
    image(path) {
      return imgixClient.buildURL(path, { auto: 'format,compress', ch: 'DPR', q: 45, w: 110, fit: 'clip' })
    }
  }
};
</script>