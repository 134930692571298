<template>
  <div class="w-64">
    <div class="flex items-center mb-2">
      <svg class="w-6 h-6 mr-1" viewBox="0 0 24 24">
        <path fill="currentColor" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
      </svg>
      <h5 v-text="$t('login.multipleAccounts')" />
    </div>
    <p class="mb-4 text-sm" v-text="$t('login.multiple')" />
    <div class="styled-form">
      <select
        name="account"
        class="w-full rounded-lg"
        :value="$store.state.account.accountId"
        @change="$store.dispatch('account/selectAccount', $event.target.value)"
        :class="{
          'error': !$store.state.account.accountId
        }"
        placeholder=" "
      >
        <option
          v-if="!$store.state.account.accountId"
          value=""
          v-text="''"
        />
        <option
          v-for="{ accountId, accountName } in $store.state.account.accounts"
          :key="accountId"
          :value="accountId"
          v-text="accountName"
          :selected="accountId === $store.state.account.accountId"
        />
      </select>
      <label for="account" class="floating-label" v-text="$t('login.select')" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccountSelector',
  data() {
    return {
      loading: true,
      showingForm: null,
      login: {
        email: '',
        password: '',  
      },
      create: {
        email: '',
        password: '',  
      },
      recover: {
        email: '',  
      },
      action: null,
      error: null,
      success: null,
      info: null,
    };
  },
};
</script>
