<template>
  <portal to="modals">
    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-30">
      <transition
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
        enter-active-class="transition-all duration-150 ease-out-quad"
        leave-active-class="transition-all duration-200 ease-in-quad"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div v-if="showBackdrop">
          <div class="absolute inset-0 bg-black opacity-50 dark:opacity-75" @click="persistent ? blink() : close()"></div>
        </div>
      </transition>

      <transition
        @before-leave="cardLeaving = true"
        @after-leave="cardLeaving = false"
        enter-active-class="transition-all duration-150 ease-out-quad"
        leave-active-class="transition-all duration-200 ease-in-quad"
        enter-class="opacity-0 scale-75"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-75"
        appear
      >
        <div v-if="showContent" :class="persistentBlink ? 'opacity-100' : 'opacity-0'" class="relative mx-2">
          <slot></slot>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: ['open', 'persistent'],
  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
      persistentBlink: true,
    };
  },
  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener('keydown', onEscape);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          this.show();
        } else {
          this.close();
        }
      },
      immediate: true,
    },
    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false;
        this.$emit('close');
      }
    },
  },
  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving;
    },
  },
  methods: {
    show() {
      this.showModal = true;
      this.showBackdrop = true;
      this.showContent = true;
    },
    blink() {
      this.persistentBlink = false;
      setTimeout(() => { this.persistentBlink = true; }, 100);
    },
    close() {
      this.showBackdrop = false;
      this.showContent = false;
    },
  },
};
</script>
