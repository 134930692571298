/* eslint-disable no-param-reassign */
import MainView from "../views/MainView.vue";
import Home from "../views/Home.vue";
import NotFound from "../components/shared/NotFound.vue";
import Loading from "../components/shared/Loading.vue";
import Timeout from "../components/shared/Timeout.vue";

const lazyLoadView = (AsyncView) => {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 400,
    error: Timeout,
    timeout: 15000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children);
    },
  });
};

const dynamicPages = window.initialData.pages.map(({ name, title, description, content }) => ({
  path: name,
  name: name,
  component: () =>
    lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/other/DocumentArticle.vue")),
  props: {
    name,
    title,
    description,
    content,
  },
  meta: {
    title,
    description,
    footer: {
      title,
      group: "shop",
    },
  },
}));

export default [
  {
    path: "/checkout/:checkoutId?/:step?/:uuid?",
    name: "checkout",
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "pages" */ "../components/checkout/CheckoutView.vue")
      ),
    props: (route) => ({
      checkoutId: route.params.checkoutId || null,
      step: route.params.step || null,
      uuid: route.params.uuid || null,
    }),
    meta: {
      disallow: true,
    },
  },
  {
    path: "",
    component: MainView,
    props: true,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
        meta: {
          noContainer: true,
        },
        props: true,
      },
      {
        path: "product/:productName/:name*",
        name: "product",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/product/Product.vue")),
        props: (route) => ({
          productName: route.params.productName,
          name: route.params.name,
          query: route.query,
        }),
        meta: {
          noContainer: true,
        },
      },
      {
        path: "shop/:category*",
        name: "shop",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/shop/Shop.vue")),
        props: (route) => ({
          category: route.params.category,
          query: route.query,
        }),
      },
      {
        path: "cart",
        name: "cart",
        props: true,
        meta: {
          disallow: true,
        },
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/store/Cart.vue")),
      },
      {
        path: "knowledge",
        name: "knowledge",
        meta: {
          footer: {
            group: "support",
            key: "meta.knowledge.title",
          },
          noContainer: true,
          fixedHeader: true,
          darkMenu: true,
        },
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/KnowledgeBase.vue")),
      },
      {
        path: "knowledge/browse/:category*",
        name: "knowledgeBrowse",
        props: (route) => ({
          categories: route.params.category ? decodeURI(route.params.category).split("/") : [],
          query: route.query.q || "",
          page: route.query.page ? parseInt(route.query.page, 10) : 1,
          tags: route.query.tags ? route.query.tags.split("~") : [],
        }),
        meta: {
          noContainer: true,
          fixedHeader: true,
          darkMenu: true,
        },
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "pages" */ "../components/knowledge/KnowledgeBase.vue")
          ),
      },
      {
        path: "knowledge/articles/:slug",
        name: "knowledgeArticle",
        props: (route) => ({
          slug: decodeURI(route.params.slug),
        }),
        meta: {
          noContainer: true,
          fixedHeader: true,
          darkMenu: true,
        },
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "pages" */ "../components/knowledge/KnowledgeArticle.vue")
          ),
      },
      {
        path: "search",
        name: "search",
        props: true,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/SearchProducts.vue")),
      },
      {
        path: "browse/:category*",
        name: "browse",
        props: (route) => ({
          category: route.params.category,
        }),
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/BrowseProducts.vue")),
      },
      {
        path: "faq",
        name: "faq",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/resources/Faq")),
        props: true,
        meta: {
          footer: {
            group: "support",
            key: "meta.faq.title",
          },
        },
      },
      {
        path: "contact",
        name: "contact",
        component: () => lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/Contact")),
        meta: {
          footer: {
            group: "support",
            key: "meta.contact.title",
          },
          noContainer: true,
        },
      },
      {
        path: "re-add",
        name: "readd",
        component: () => lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/READD.vue")),
        meta: {
          footer: {
            group: "about",
            key: "meta.readd.title",
          },
        },
      },
      {
        path: "rnd",
        name: "rnd",
        component: () => lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/Rnd.vue")),
        meta: {
          footer: {
            group: "about",
            key: "meta.rnd.title",
          },
        },
      },
      {
        path: "sustainability",
        name: "sustainability",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/Sustainability.vue")),
        meta: {
          footer: {
            group: "about",
            key: "meta.sustainability.title",
          },
        },
      },
      {
        path: "about",
        name: "about",
        component: () => lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/About.vue")),
        meta: {
          footer: {
            group: "about",
            key: "meta.about.title",
          },
        },
      },
      {
        path: "resellers",
        name: "resellers",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/Resellers.vue")),
        menu: true,
        meta: {
          footer: {
            group: "shop",
            key: "meta.resellers.title",
          },
          noContainer: true,
        },
      },
      {
        path: "partner-program",
        name: "partnerProgram",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/PartnerProgram.vue")),
        menu: true,
        meta: {
          footer: {
            group: "b2b",
            key: "meta.partnerProgram.title",
          },
          noContainer: true,
        },
      },
      {
        path: "end-product-use",
        name: "endProductUse",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/EndProductUse.vue")),
        menu: true,
        meta: {
          footer: {
            group: "b2b",
            key: "meta.endProductUse.title",
          },
          noContainer: true,
        },
      },
      {
        path: "affiliate",
        name: "affiliate",
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/Affiliate.vue")),
        menu: true,
        meta: {
          footer: {
            group: "b2b",
            key: "meta.affiliate.title",
          },
          noContainer: true,
        },
      },
      {
        path: "custom-filaments",
        name: "custom-filaments",
        component: () => lazyLoadView(import(/* webpackChunkName: "pages" */ "../views/OEM.vue")),
        menu: true,
        meta: {
          footer: {
            group: "b2b",
            key: "meta.oem.title",
          },
          noContainer: true,
        },
      },
      {
        path: "account",
        component: {
          template:
            '<div style="min-height: 60vh" class="pt-2 px-4 pb-12"><AccountBar /><router-view/></div>',
          components: {
            AccountBar: () =>
              lazyLoadView(
                import(/* webpackChunkName: "pages" */ "../components/account/AccountBar")
              ),
          },
        },
        meta: {
          raisedMenu: true,
        },
        props: true,
        children: [
          {
            path: "",
            name: "account",
            component: () =>
              lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/account/Account")),
            meta: {
              footer: {
                group: "account",
                key: "meta.account.title",
              },
              raisedMenu: true,
              disallow: true,
            },
          },
          {
            path: "addclub",
            name: "addclub",
            component: () =>
              lazyLoadView(import(/* webpackChunkName: "pages" */ "../components/account/AddClub")),
            meta: {
              footer: {
                group: "account",
                key: "meta.addclub.title",
              },
              raisedMenu: true,
            },
          },
          // {
          //   path: 'orders',
          //   name: 'orders',
          //   component: () => lazyLoadView(import(/* webpackChunkName: "pages" */'../components/account/Orders')),
          //   meta: {
          //     footer: {
          //       group: 'account',
          //       key: 'meta.orders.title'
          //     },
          //     raisedMenu: true,
          //     disallow: true,
          //   },
          // },
          {
            path: "settings",
            name: "settings",
            component: () =>
              lazyLoadView(
                import(/* webpackChunkName: "pages" */ "../components/account/Settings")
              ),
            meta: {
              footer: {
                group: "account",
                key: "meta.settings.title",
              },
              raisedMenu: true,
              disallow: true,
            },
          },
          {
            path: "login",
            name: "login",
            meta: {
              raisedMenu: true,
              disallow: true,
            },
            props: (route) => ({
              redirect: route.query.redirect,
            }),
            component: {
              template: '<div class="flex justify-center mt-6"><Login /></div>',
              components: {
                Login: () =>
                  lazyLoadView(
                    import(/* webpackChunkName: "pages" */ "../components/account/Login")
                  ),
              },
              props: {
                redirect: {
                  type: String,
                  default: null,
                },
              },
              computed: {
                authenticated() {
                  return this.$store.getters["account/authenticated"];
                },
              },
              watch: {
                authenticated(authenticated) {
                  if (authenticated && this.redirect) this.$router.push(this.redirect);
                },
              },
            },
          },
        ],
      },
      ...dynamicPages,
      {
        path: "*",
        name: "404",
        component: NotFound,
        meta: {
          disallow: true,
        },
      },
    ],
  },
];
