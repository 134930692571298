var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProductCard',{staticClass:"w-full relative pr-2 shadow-sm border z-10 hover:bg-gray-100 rounded-md flex items-center dark:bg-gray-700 dark:hover:bg-gray-600",staticStyle:{"height":"107px"},attrs:{"product":_vm.product,"index":_vm.index,"listId":_vm.listId,"listName":_vm.listName},scopedSlots:_vm._u([{key:"default",fn:function({
      product: {
        thumbnail: {
          src,
          description,
          bgColor
        },
        texts: {
          title
        },
        bullets
      },
      displayLabels,
    }){return [_c('div',{staticClass:"p-1 mr-1 flex-shrink-0 w-full flex justify-center items-center",staticStyle:{"width":"100px","height":"87.5px"},style:(!!_vm.showThumbnailBgColor && !!bgColor ? `background: ${bgColor}` : '')},[_c('img',{staticClass:"object-contain",attrs:{"src":_vm.image(src),"alt":`Add North 3D filament ${description}`}}),_vm._l((displayLabels),function({ text, classes },index){return _c('div',{key:text,staticClass:"absolute left-0 top-0",style:(`margin-top: ${index * 1.3}rem`)},[_c('div',{staticClass:"h-0 relative",class:classes,style:(`width: ${6 - index}rem; border-top-width: 20px; border-right: 10px solid transparent;`)},[_c('div',{staticClass:"text-xxs absolute inset-0 py-1 pl-2 overflow-visible font-medium uppercase",staticStyle:{"top":"-21px"},domProps:{"textContent":_vm._s(text)}})])])})],2),_c('div',{staticClass:"flex-shrink flex-grow whitespace-nowrap truncate overflow-hidden"},[_c('h5',{staticClass:"font-medium text-sm whitespace-nowrap truncate overflow-hidden",domProps:{"innerHTML":_vm._s(title)}}),(bullets.length)?_c('div',{staticClass:"mb-2 flex-shrink flex-grow"},_vm._l((bullets),function(bullet,index){return _c('div',{key:`${index}${bullet}`,staticClass:"flex flex-shrink items-center text-sm"},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"}})]),_c('p',{staticClass:"w-full whitespace-nowrap truncate overflow-hidden",staticStyle:{"min-width":"0"},domProps:{"innerHTML":_vm._s(bullet)}})])}),0):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }