export default {
  namespaced: true,
  state: {
    showShopSettings: false,
    currency: process.env.VUE_APP_CURRENCY,
    showWithVat: process.env.VUE_APP_SHOW_WITH_VAT === "true",
    affiliates: {},
    affiliateKeys: ["affiliate", "utm_source", "utm_medium", "utm_campaign", "utm_content"],
  },
  mutations: {
    SET_SHOW_SHOP_SETTINGS(state, display) {
      state.showShopSettings = display;
    },
    SET_CURRENCY(state, currency) {
      state.currency = currency;
    },
    SET_SHOW_WITH_VAT(state, showWithVat) {
      state.showWithVat = showWithVat;
    },
    SET_AFFILIATE(state, affiliates) {
      state.affiliates = { ...state.affiliates, ...affiliates };
    },
  },
  actions: {
    getAffiliate({ state, commit }) {
      const urlParams = new URLSearchParams(window.location.search);
      const affiliates = {};
      state.affiliateKeys.forEach((key) => {
        if (urlParams.get(key)) affiliates[key] = urlParams.get(key);
      });
      if (Object.keys(affiliates).length) commit("SET_AFFILIATE", affiliates);
    },
  },
};
