import Rollbar from 'rollbar';
import Vue from 'vue';

const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.VUE_APP_DISABLE_ROLLBAR !== 'true',
  environment: process.env.VUE_APP_NODE_ENV,
  source_map_enabled: true,
  guess_uncaught_frames: true,
  payload: {
    domain: process.env.VUE_APP_DOMAIN,
    lang: process.env.VUE_APP_LANGUAGE,
    client: {
      javascript: {
        code_version: process.env.VUE_APP_ROLLBAR_VERSION,
      },
    },
  },
});

Vue.prototype.$rollbar = rollbar

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err;
};

export default rollbar