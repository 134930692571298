import { format } from 'date-fns';

const Timestamp = {
  install(Vue) {

    const timestamp = (timestamp, pattern = 'yyyy-MM-dd') => format(timestamp, pattern);

    Vue.filter('timestamp', timestamp);
    Vue.prototype.$timestamp = timestamp;
  },
};

export default Timestamp;
