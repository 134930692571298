<template>
  <div class="flex justify-center align-center items-center w-full" style="min-height: 60vh;">
    <div class="rounded-lg border p-6">
      <h4 class="font-medium" v-text="$t('site.timeout')" />
      <p class="max-w-xs mb-4 mt-2" v-text="$t('site.noLoad')" />
      <button
        class="main-button"
        v-text="$t('common.reload')"
        @click="reload"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Timeout',
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>
