export default {
  namespaced: true,
  state: {
    country: null,
    company: false,
  },
  mutations: {
    SET_COUNTRY(state, country) {
      state.country = country;
    },
    SET_COMPANY(state, company) {
      state.company = company
    },
  },
};
