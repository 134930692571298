import store from "@/store";
import { listAccounts, createAccount } from "@/services/api";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

initializeApp({
  apiKey: "AIzaSyB2K2kCDTLTbBhQuI6tOqpa9NuxD6cOP_o",
  authDomain: "addnorth-com.firebaseapp.com",
  // authDomain: 'auth.addnorth.com',
});

const auth = getAuth();
let authObserver = null;

const onUser = async (user) => {
  // Only allow non-anonymous with verified email login (or facebook login)
  if (!user || user.isAnonymous) {
    store.dispatch("account/signOut");
  } else {
    // Get accounts
    const accounts = await listAccounts();
    store.commit("account/SET_ACCOUNTS", accounts);
    // Set default, create or make chose default
    if (!accounts.length) {
      await createAccount({
        auth: false,
        email: user.email,
        referalToken: store.state.account.referalToken,
      });
      return onUser(user);
    } else if (accounts.length === 1) {
      store.dispatch("account/selectAccount", accounts[0].accountId);
    } else if (accounts.length > 1 && store.state.account.preferedAccountId) {
      store.dispatch("account/selectAccount", store.state.account.preferedAccountId);
    }
    store.dispatch("account/authSignIn", user);
  }
  if (!store.state.account.authReady) store.commit("account/SET_AUTH_READY", true);
};

const logOut = () => {
  store.dispatch("account/signOut");
  return signOut(auth);
};

const onError = (error) => {
  if (window.Rollbar) window.Rollbar.error(`FB auth observer error: ${error.message}`, error);
  return logOut();
};

const loadAuth = async (attempt = 1) => {
  try {
    // Create observer
    if (authObserver) authObserver();
    authObserver = onAuthStateChanged(auth, onUser, onError);
  } catch (error) {
    if (attempt <= 3) return loadAuth(attempt + 1);
    if (window.Rollbar) window.Rollbar.error(`LOAD auth error 3 attempt: ${error.message}`, error);
    throw error;
  }
};

const unloadAuth = () => {
  if (authObserver) authObserver();
  if (store.state.account.authReady) store.commit("account/SET_AUTH_READY", false);
};

const getIdToken = () => {
  try {
    if (!auth) throw new Error("No auth");
    if (!auth.currentUser) return null;
    return auth.currentUser.getIdToken();
  } catch {
    return null;
  }
};

export {
  loadAuth,
  unloadAuth,
  getIdToken,
  logOut,
  auth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  GoogleAuthProvider,
  FacebookAuthProvider,
};
