var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"block",attrs:{"to":`/knowledge/articles/${_vm.article.slug}`}},[_c('div',{staticClass:"knowledge-card",class:{
      fixed: !_vm.niceCard
    }},[_c('img',{class:{
        'bg-white': !_vm.niceCard
      },attrs:{"src":_vm.image(_vm.article.cover.src),"alt":_vm.article.cover.alt}}),_c('div',{staticClass:"flex flex-col overflow-hidden absolute bottom-0.5 left-1 right-1",class:{
        'top-16': _vm.niceCard,
        'top-1/2': !_vm.niceCard
      }},[_c('div',{staticClass:"text-sm font-medium uppercase flex-shrink-0 overlay",domProps:{"innerHTML":_vm._s(_vm.article.type)}}),_c('h6',{staticClass:"truncate font-light flex-shrink-0 leading-relaxed overlay",domProps:{"innerHTML":_vm._s(_vm.article.title)}}),_c('p',{staticClass:"text-xs flex-grow flex-shrink overflow-hidden overflow-ellipsis leading-tight mb-1 overlay",domProps:{"innerHTML":_vm._s(_vm.article.description)}}),_c('div',{staticClass:"flex justify-between items-end flex-shrink-0"},[_c('div',{staticClass:"flex flex-shrink-0 overflow-hidden z-10"},_vm._l((_vm.article.tags),function(tag){return _c('div',{key:`${_vm.article.slug}${tag}`,staticClass:"text-xs font-medium uppercase rounded-lg px-2 py-1 m-1 bg-gray-200 dark:bg-gray-600",domProps:{"innerHTML":_vm._s(`#${tag}`)}})}),0),(_vm.article.onlyEnglish)?_c('div',{staticClass:"text-xs overlay flex-shrink-0",domProps:{"textContent":_vm._s(_vm.$t('common.onlyInEnglish'))}}):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }