export default {
  namespaced: true,
  state: {
    articles: window.initialData.articles,
    products: window.initialData.products,
    categories: window.initialData.settings.categories,
    variations: window.initialData.variations,
    resellers: window.initialData.resellers,
    wishList: [],
    categorySort: [
      "Recycled",
      "Återvunnet",
      "Filaments by material",
      "Filament efter material",
      "Filaments by function",
      "Filament efter funktion",
      "Printers",
      "Skrivare",
      "Fäste för byggplattan",
      "Print Bed Adhesive",
      "Nozzlar",
      "Nozzles",
    ],
    attributes: ["diameter", "size", "color", "material", "hotend", "version", "ho", "listPrice"],
    seenMessages: [],
    messages: window.initialData.settings.messages,
  },
  mutations: {
    SEEN_MESSAGE(state, message) {
      state.seenMessages.push(message);
    },
    WISH_PRODUCT(state, product) {
      state.wishList.push(product);
    },
  },
  actions: {},
  getters: {
    unseenMessages: (state) =>
      state.messages.filter(({ message }) => !state.seenMessages.includes(message)),
    sortAttributes: () => (a, b, attribute) => {
      if (["diameter", "size", "version"].includes(attribute)) return parseFloat(a) - parseFloat(b);
      if (attribute === "ho") return (a ? -1 : 0) - (b ? -1 : 0);
      if (attribute === "giftCardValue") {
        return parseInt(a.split(" ")[0]) - parseInt(b.split(" ")[0]);
      }
      return a.localeCompare(b, "sv");
    },
    sortCategories: (state) => (a, b) => {
      const indexA = state.categorySort.indexOf(a);
      const indexB = state.categorySort.indexOf(b);
      if (indexA === -1 && indexB === -1) return a.localeCompare(b, "sv");
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    },
    reduceSplitCategory: (_state, getters, _rootState) => (
      carry = [],
      categories = [],
      parents = [],
      count
    ) => {
      const [label, ...rest] = categories;
      const index = carry.findIndex((i) => i.label === label);
      if (index === -1) {
        const children = rest.length
          ? getters["reduceSplitCategory"]([], rest, [...parents, label], count)
          : [];
        children.sort((a, b) => getters["sortCategories"](a.label, b.label));
        carry.push({
          label,
          category: [...parents, label],
          path: [...parents, label].join("/"),
          count: rest.length === 0 ? count : null,
          children,
        });
      } else if (rest.length) {
        carry[index].children = getters["reduceSplitCategory"](
          carry[index].children,
          rest,
          [...parents, label],
          count
        );
        carry[index].children.sort((a, b) => getters["sortCategories"](a.label, b.label));
      }
      return carry;
    },
    categoryFacetsToList: (_state, getters) => (obj) => {
      const categories = Object.entries(obj).reduce((carry, [key, value]) => {
        if (key.substr(0, 10) === "categories") {
          return [...carry, ...getters["categoryFacetsToList"](value)];
        }
        return getters["reduceSplitCategory"](carry, key.split(">"), [], value);
      }, []);
      categories.sort((a, b) => getters["sortCategories"](a.label, b.label));
      return categories;
    },
    displayAttribute: () => (attribute, refinement) => {
      // if (attribute === 'listPrice')
      let suffix = "";
      if (attribute === "size") suffix = "g";
      if (["diameter", "version"].includes(attribute)) suffix = "mm";
      if (attribute === "ho") return refinement ? "HO" : "Regular";
      return `${refinement}${suffix}`;
    },
    // getFilaments: state => state.products.filter(({ filament }) => filament),
    // getProduct: state => productId => state.products.find(i => i.id === productId),
    siteProductCategories: (state, getters) => {
      return getters.categoryFacetsToList(state.categories);
    },
  },
};
