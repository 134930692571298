<template>
  <div
    class="flex items-end align-bottom overflow-hidden"
    :class="{
      'flex-col': !horizontal,
      'flex-row space-x-1': horizontal,
    }"
  >
    <div
      v-if="discount && discount.discountPercentage > 0 && showSales"
      class="line-through font-medium flex-shrink-0 text-right"
      :class="{
        'text-lg leading-5': size === 'large',
        'text-sm': size === 'small',
      }"
    >
      {{ msrp | currency({ currency, quantity, showWithVat }) }}
    </div>
    <div
      class="leading-none font-medium flex-shrink-0 text-right"
      :class="{
        'text-red-400': discount && discount.discountPercentage > 0,
        'text-lg': size === 'medium',
        'text-2xl': size === 'large',
        'text-sm': size === 'small',
      }"
    >
      {{
        msrp
          | currency({
            currency,
            discount: !!discount ? discount.discountPercentage : 0,
            quantity,
            showWithVat,
          })
      }}
    </div>
    <div
      v-if="discount && discount.discountPercentage > 0 && showSales"
      v-text="discount.type === 'agreement' ? $t('product.agreementPrice') : discount.name"
      class="text-red-400 overflow-ellipsis overflow-hidden w-full truncate"
      :class="{
        'text-xs leading-4': size === 'large',
        'text-sm': size === 'medium',
        'text-xs': size === 'small',
      }"
    />
    <div
      v-if="showVatText"
      v-text="$t(`store.vat.${showWithVat ? 'incVat' : 'exVat'}`)"
      class="text-right flex-shrink-0"
      :class="{
        'text-xs leading-4': size === 'large',
        'text-sm': size === 'medium',
        'text-xs': size === 'small',
      }"
    />
  </div>
</template>
<script>
export default {
  name: "ProductPrice",
  props: {
    msrp: {
      type: Number,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    discount: {
      type: Object,
      default: null,
    },
    showWithVat: {
      type: Boolean,
      default: true,
    },
    showVatText: {
      type: Boolean,
      default: false,
    },
    showSales: {
      type: Boolean,
      default: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>
