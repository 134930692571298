import store from '../store';
import { getIdToken } from './auth'
import rollbar from './rollbar';

function encodeQueryString(params) {
  const keys = Object.keys(params)
  return keys.length
      ? '?' + keys
          .map(key => encodeURIComponent(key)
              + '=' + encodeURIComponent(params[key]))
          .join('&')
      : ''
}

const wait = attempt => new Promise(r => setTimeout(r, Math.max(0, 400 * (attempt - 1) - 200)));

const request = async (params, attempt) => {
  const { path, headers = {}, method, query = {}, data } = params
  // Headers
  Object.assign(headers, { 'Content-Type': 'application/json' }, headers)
  
  // Auth headers and query params
  const token = await getIdToken()
  if (token) {
    Object.assign(headers, {
      'Authorization': `Bearer ${token}`,
    })
    Object.assign(query, {
      accountId: store.state.account.accountId
    })
  }

  // Query
  Object.assign(query, {
    environment: process.env.VUE_APP_NODE_ENV,
    language: process.env.VUE_APP_LANGUAGE
  })

  const fetchOptions = {
    method,
    headers
  }
  // Body
  if (data) fetchOptions.body = JSON.stringify(data);

  return fetch(`/oldapi/${path}${encodeQueryString(query)}`, fetchOptions)
    .then((response) => Promise.all([ response, response.text() ]))
    .then(([response, text]) => {
      if (!response.ok) throw new ApiError(text || response.statusText, response)
      if (
        !response.headers ||
        !response.headers.get('Content-Type') ||
        !response.headers.get('Content-Type').includes('application/json')
      ) throw new ApiError('Request did not return json type', response)
      try {
        const json = JSON.parse(text)
        return json;
      } catch {
        throw new ApiError('Could not parse request json', response)
      }
    })
    .catch((error) => {
      // Retry if applicable
      if (method === 'GET' && attempt && attempt < 10) return wait(attempt).then(() => request(params, attempt + 1))
      const { message, response } = error
      // Report error
      if (error instanceof ApiError && response.status === 402) {
        reportApiError({ message, response, params, attempt })
        throw new PaymentError(message)
      } else if (error instanceof ApiError && response.status === 403) {
        reportApiError({ message, response, params, attempt })
        throw new AuthError(message)
      } else if (response.status === 404) {
        throw new Error('NotFound')
      } else {
        reportApiError({ message, response, params, attempt })
        throw error;
      }
    });
}

const reportApiError = ({ message, response: { status = null, statusText = null } = { status: null, statusText: null}, params = {}, attempt = {} }) => {
  let msg = message;
  if (status === 402) {
    msg = `AN Payment Error: ${msg}`
  } else if (status === 403) {
    msg = `AN Auth Error: ${msg}`
  } else {
    msg = `AN API Error: ${msg}`
  }
  rollbar.error(msg, { response: { status, statusText }, params, attempt })
}

class ApiError extends Error {
  constructor(message, response) {
    super(message);
    this.name = "ApiError";
    this.response = response;
  }
}

class PaymentError extends Error {
  constructor(message) {
    super(message);
    this.name = "PaymentError";
  }
}

class AuthError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthError";
  }
}

export { ApiError, PaymentError };

// GET
export function getInventory(sku, quantity) { return request({ path: 'inventory', method: 'GET', query: { sku, quantity } }, 1); }
export function getVariations(productId = null) { return request({ path: 'variations', method: 'GET', query: { productId } }, 1); }
export function listAccounts() { return request({ path: 'account/list', method: 'GET' }, 1); }
export function joinClub(id, referalToken = null) { return request({ path: `account/${id}/join`, method: 'POST', data: { referalToken } }); }

// POST
export function createAccount(data) { return request({ path: 'account/create', method: 'POST', data }); }
export function sendReferal(id, email, currency, domain) { return request({ path: `account/${id}/sendReferal`, method: 'POST', data: { email, currency, domain } }); }

// Checkout
export function checkoutCreate(data) { return request({ path: 'checkout', method: 'POST', data }); }
export function checkoutGet(id) { return request({ path: `checkout/${id}`, method: 'GET' }, 6); }
export function checkoutCoupon(data, id) { return request({ path: `checkout/${id}/coupon`, method: 'POST', data }); }
export function checkoutAccount(data, id) { return request({ path: `checkout/${id}/account`, method: 'POST', data }); }
export function checkoutCountry(data, id) { return request({ path: `checkout/${id}/country`, method: 'POST', data }); }
export function checkoutCompany(data, id) { return request({ path: `checkout/${id}/company`, method: 'POST', data }); }
export function checkoutNoVatNumber(data, id) { return request({ path: `checkout/${id}/noVatNumber`, method: 'POST', data }); }
export function checkoutAddress(data, id) { return request({ path: `checkout/${id}/address`, method: 'POST', data }); }
export function checkoutShipping(data, id) { return request({ path: `checkout/${id}/shipping`, method: 'POST', data }); }
export function checkoutAgent(data, id) { return request({ path: `checkout/${id}/agent`, method: 'POST', data }); }
export function checkoutShippingComplete(data, id) { return request({ path: `checkout/${id}/shippingComplete`, method: 'POST', data }); }
export function checkoutPayment(data, id) { return request({ path: `checkout/${id}/payment`, method: 'POST', data }); }
export function checkoutPoints(data, id) { return request({ path: `checkout/${id}/points`, method: 'POST', data }); }
export function checkoutPlace(data, id) { return request({ path: `checkout/${id}/place`, method: 'POST', data }); }
export function checkoutSwishStatus(uuid, id) { return request({ path: `checkout/${id}/swishStatus/${uuid}`, method: 'GET' }); }