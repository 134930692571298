<template>
  <aside
    v-if="(!cookiesSelected || messages.length) && !bot && !preRender"
    class="fixed inset-x-0 z-10 messages"
  >
    <div
      v-if="!cookiesSelected"
      class="opacity-90 text-center p-12 bg-black text-white flex flex-col items-center space-y-6"
    >
      <div class="max-w-2xl text-justify">
        <span v-html="$t('site.cookiesText')" />
        <router-link
          class="text-white underline ml-1"
          to="/privacy"
          @click="agreeCookies(true)"
          v-text="$t('site.cookiesLink')"
        />
      </div>
      <div class="flex flex-col sm:flex-row justify-end w-full space-y-6 sm:space-y-0 sm:space-x-6">
        <button
          class="border border-gray-200 hover:bg-gray-700 leading-none text-white font-medium py-3 px-3 rounded-lg outline-none"
          @click="agreeCookies(false)"
        >
          {{ $t("site.onlyEssentials") }}
        </button>
        <button
          class="bg-white hover:bg-gray-200 text-black leading-none font-medium py-3 px-12 rounded-lg outline-none"
          @click="agreeCookies(true)"
        >
          {{ $t("site.accept") }}
        </button>
      </div>
    </div>
    <div v-else-if="messages.length">
      <div
        v-for="({ pallette, message }, index) in messages"
        :key="index"
        :class="pallettes[pallette] || pallettes['black']"
        class="text-center py-2 text-sm flex items-center content-center justify-center z-40"
        style="min-height: 50px"
      >
        {{ message }}
        <button class="ml-4" @click="seenMessage(message)" aria-label="close">
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
            <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: "Messages",
  data() {
    return {
      bot: /bot|crawl|spider/i.test(window.navigator.userAgent),
      preRender: !!window._PRERENDER_DATA,
      pallettes: {
        green: "bg-green-500 text-white",
        black: "bg-black text-white",
        red: "bg-red-500 text-white",
        blue: "bg-blue-500 text-white",
      },
      accountPages: false,
    };
  },
  computed: {
    cookiesSelected() {
      return this.$store.getters["shared/cookiesSelected"];
    },
    messages() {
      return this.$store.getters["content/unseenMessages"];
    },
  },
  methods: {
    agreeCookies(agreed) {
      if (agreed) {
        this.$store.dispatch("shared/agreedCookies");
      } else {
        this.$store.commit("shared/AGREE_COOKIES", false);
      }
    },
    seenMessage(message) {
      this.$store.commit("content/SEEN_MESSAGE", message);
    },
  },
};
</script>
<style scoped>
#app:not(.raised-menu) .messages {
  bottom: 60px;
}
#app.raised-menu .messages {
  bottom: 120px;
}
@media (min-width: 1024px) {
  .messages {
    bottom: 0px !important;
  }
}
</style>
