import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && ["shop", "knowledgeBrowse"].includes(to.name)) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(savedPosition), 200);
      });
    }
    if (savedPosition) return savedPosition;
    if (to.name === "product" && from && from.name === "product") return null;
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.query.fbclid) {
    store.dispatch("shared/setFbClid", to.query.fbclid);
  }
  store.dispatch("shared/sendAnalytics", { event: "page_view" });
  return next();
});

export default router;
