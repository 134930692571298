<template>
  <footer class="bg-footer dark:bg-gray-800 w-full pb-6">
    <Newsletter identifier="footerSubscribe" class="my-16" />
    <div class="flex flex-col items-center gap-y-8 lg:gap-y-10 mb-10 px-4">
      <nav
        class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 lg:gap-x-10"
        role="navigation"
      >
        <div v-for="(pages, header) in categories" :key="header" class="flex md:justify-center">
          <div>
            <p
              class="uppercase text-base font-bold text-gray-900 dark:text-white mb-2"
              v-text="$t(`pageCategories.${header}`)"
            />
            <div class="flex flex-col space-y-2">
              <router-link
                v-for="{ title, key, paths } in pages"
                :key="paths.join()"
                :to="`/${paths.join('/')}`"
                class="block dark:text-gray-100 hover:text-gray-600 dark:hover:text-gray-200"
                v-text="title ? title : $t(key)"
              />
            </div>
          </div>
        </div>
      </nav>
      <div style="max-width: 320px">
        <router-link to="/">
          <img
            :src="
              imgixClient.buildURL('logos/an_logo_black.svg', {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                w: 220,
                fit: 'clip',
              })
            "
            alt="add:north 3D filaments"
            class="object-contain dark:hidden"
            style="width: 220px; height: 59px"
            width="220"
            height="59"
          />
          <img
            :src="
              imgixClient.buildURL('logos/an_logo_white.svg', {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                w: 220,
                fit: 'clip',
              })
            "
            alt="add:north 3D filaments"
            class="object-contain hidden dark:block"
            style="width: 220px; height: 59px"
            width="220"
            height="59"
          />
        </router-link>
        <div
          class="w-full text-sm mt-2 text-justify dark:text-white"
          v-text="$t('meta.about.description')"
        />
        <SocialMedia class="w-full" />
      </div>
    </div>
    <div class="mb-64 w-full space-y-8 justify-center">
      <div class="elfsight-app-f21f8819-c2af-427e-a56e-056223e10caf" />
      <div v-for="{ textKey, urls } in badges" :key="textKey" class="flex items-center flex-col">
        <div class="font-medium mb-2 dark:text-white" v-text="$t(textKey)" />
        <div class="mx-2 grid gap-2 sm:gap-4 md:gap-6 grid-flow-col" style="height: 30px">
          <img
            v-for="url in urls"
            :key="url"
            :src="
              imgixClient.buildURL(url, {
                auto: 'format,compress',
                ch: 'DPR',
                q: 45,
                h: 30,
                fit: 'clip',
              })
            "
            style="height: 30px"
            class="object-contain dark:bg-white"
            height="30"
            width="90"
            :alt="url.split('/')[1]"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import imgixClient from "../../services/imgixClient";
import Newsletter from "./Newsletter.vue";
import SocialMedia from "./SocialMedia.vue";
import routes from "../../router/routes";
import { sites } from "@/internationalization";

export default {
  name: "Footer",
  components: {
    Newsletter,
    SocialMedia,
  },
  data() {
    return {
      imgixClient,
    };
  },
  computed: {
    categories() {
      return this.getFooters(routes);
    },
    badges() {
      return [
        {
          textKey: "store.payment.paymentOptions",
          urls: sites
            .find(({ code }) => code === process.env.VUE_APP_LANGUAGE)
            .paymentMethods.map((method) => `paymentLogos/${method}.svg`),
        },
        {
          textKey: "store.shipping.weShipWith",
          urls: sites
            .find(({ code }) => code === process.env.VUE_APP_LANGUAGE)
            .carriers.map((carrier) => `shippingLogos/${carrier}.svg`),
        },
      ];
    },
  },
  methods: {
    getFooters(arr, carry = {}, prevPaths = []) {
      return arr.reduce((c, route) => {
        const {
          meta: {
            footer: { group = null, key = null, title = null } = {
              group: null,
              title: null,
              key: null,
            },
          } = { group: null, title: null, key: null },
          path,
          children,
        } = route;
        const paths = [...prevPaths];
        if (path.length) paths.push(path.split("/")[0]);
        if (children) c = this.getFooters(children, c, paths);
        if (group) {
          if (!c[group]) c[group] = [];
          c[group].push({ title, key, paths });
        }
        return c;
      }, carry);
    },
  },
};
</script>
