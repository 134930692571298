<template>
  <div class="min-w-full min-h-full fixed inset-0 flex flex-col justify-center items-center bg-white bg-opacity-75 z-20">
    <div class="spinner large h-12 w-12" />
    <div v-if="longLoad" class="text-xs" v-text="`${$t('site.longLoad')}...`" />
  </div>
</template>
<script>
export default {
  name: 'Loading',
  data() {
    return {
      longLoad: false,
    };
  },
  mounted() {
    setTimeout(() => { this.longLoad = true; }, 3000);
  },
};
</script>
