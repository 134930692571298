<template>
  <div>
    <div class="styled-form w-52 relative">
      <select
        name="currencySelector"
        class="w-full rounded-lg appearance-none"
        placeholder=" "
        id="currencySelector"
        v-model="currency"
      >
        <option
          v-for="curr in currencies"
          :key="curr"
          :value="curr"
          v-text="curr"
          :selected="curr === currency"
        />
      </select>
      <label for="currencySelector" class="floating-label" v-text="$t('common.currency')" />
    </div>
  </div>
</template>
<script>
import { currencies } from '@/internationalization';
export default {
  name: 'CurrencySelector',
  data() {
    return {
      currencies,
    }
  },
  computed: {
    currency: {
      set(currency) {
        this.$store.commit('store/SET_CURRENCY', currency)
      },
      get() {
        return this.$store.state.store.currency;
      },
    },
  },
};
</script>
