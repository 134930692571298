<template>
  <section v-if="items.length">
    <div class="flex justify-between" v-if="title">
      <h4 class="pb-2 font-bold dark:text-white" v-text="title" />
      <router-link
        v-if="viewAllLink"
        :to="viewAllLink"
        class="hover:cursor-pointer hover:underline text-sm flex items-center flex-shrink-0"
      >
        {{ viewAllText || $t('common.showAll') }}
        <svg
          class="static w-4 h-4 ml-1 stroke-current transform -rotate-90"
          viewBox="0 0 20 20"
        >
          <polyline fill="none" points="16 7 10 13 4 7" />
        </svg>
      </router-link>
    </div>
    <div class="flex justify-center relative w-full" ref="carousel-wrapper">
      <div
        class="my-0 overflow-hidden"
        :style="`height: ${cardHeight}px`"
      >
        <div ref="carousel-hide">
          <ul
            class="flex justify-start transition-all duration-200 ease-in"
            ref="carousel"
            :style="`transform: translateX(${offset}px);`"
          >
            <li
              v-for="(item, index) in items"
              :key="`${title}${index}`"
              class="mr-1 sm:mr-2 pb-4"
            >
              <slot
                name="item"
                :item="item"
                :index="index"
                :listName="listName"
                :listId="listId"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="arrows && canScrollLeft"
          class="inline-flex absolute inset-y-0 z-10 items-center -left-7 sm:left-0"
        >
          <button
            class="rounded-full p-2 outline-none focus:outline-none text-gray-700 dark:text-white md:dark:text-gray-700 md:bg-gray-50 md:border-gray-200 md:hover:bg-gray-200"
            @click="left"
            aria-label="previous"
          >
            <svg
              class="static w-6 h-6 stroke-current transform rotate-90"
              viewBox="0 0 20 20"
            >
              <polyline fill="none" points="16 7 10 13 4 7" />
            </svg>
          </button>
        </div>
        <div
          v-if="arrows && canScrollRight"
          class="inline-flex absolute inset-y-0 z-10 items-center -right-7 sm:right-0"
        >
          <button
            class="rounded-full p-2 outline-none focus:outline-none text-gray-700 dark:text-white md:dark:text-gray-700 md:bg-gray-50 md:border-gray-200 md:hover:bg-gray-200"
            @click="right"
            aria-label="next"
          >
            <svg
              class="static w-6 h-6 stroke-current transform -rotate-90"
              viewBox="0 0 20 20"
            >
              <polyline fill="none" points="16 7 10 13 4 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    viewAllLink: {
      type: String,
      default: null,
    },
    viewAllText: {
      type: String,
      default: null,
    },
    limit: {
      type: Number,
      default: 6,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    listName: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      position: 0,
      offset: 0,
      carouselWidth: 0,
      fullCarouselWidth: 0,
      cardWidth: 0,
      cardHeight: 0,
      cardMarginRight: 0,
    };
  },
  computed: {
    canScrollLeft() {
      return this.offset < 0
    },
    canScrollRight() {
      return (-this.offset + this.carouselWidth) < this.fullCarouselWidth;
    }
  },
  methods: {
    display() {
      if (this.items.length) {
        // Get card margin
        const carousel = this.$refs.carousel
        const carouselHide = this.$refs['carousel-hide']
        const card = carousel.querySelector('li');
        const cardStyle = card.currentStyle || window.getComputedStyle(card)
        this.cardMarginRight = Number(cardStyle.marginRight.match(/\d+/g)[0]);
        this.cardWidth = card.offsetWidth
        this.cardHeight = card.offsetHeight
        // See how many cards fit
        const carouselMaxW = this.$refs['carousel-wrapper'].offsetWidth
        let limit = Math.max(1, Math.floor(carouselMaxW / (this.cardWidth + this.cardMarginRight)))
        limit = Math.min(limit, this.items.length)
        this.fullCarouselWidth = this.items.length * this.cardWidth + (this.items.length - 1) * this.cardMarginRight
        this.carouselWidth = limit * this.cardWidth + (limit - 1) * this.cardMarginRight
        carousel.style.width = `${this.carouselWidth}px`
        carouselHide.style.width = `${this.carouselWidth}px`
      }
    },
    left() {
      if (this.canScrollLeft) this.offset += this.cardWidth + this.cardMarginRight;
    },
    right() {
      if (this.canScrollRight) this.offset -= this.cardWidth + this.cardMarginRight;
    }
  },
  mounted() {
    this.display()
    window.addEventListener('resize', this.display);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.display);
  },
};
</script>
