export default {
  namespaced: true,
  state: {
    addClubInfo: {
      "invitationBonus": {
        "EUR": 5,
        "SEK": 50,
        "GBP": 5,
        "NOK": 50,
        "DKK": 50,
        "USD": 5
      },
      "levelPeriodDays": 365,
      "tiers": [
        {
          "threshold": {
            "NOK": 0,
            "EUR": 0,
            "USD": 0,
            "DKK": 0,
            "GBP": 0,
            "SEK": 0
          },
          "rewardPercentage": 0.03,
          "name": "standard"
        },
        {
          "name": "pro",
          "threshold": {
            "SEK": 150,
            "DKK": 110,
            "GBP": 15,
            "EUR": 15,
            "USD": 20,
            "NOK": 150
          },
          "rewardPercentage": 0.06
        },
        {
          "name": "elite",
          "threshold": {
            "EUR": 30,
            "USD": 40,
            "NOK": 300,
            "GBP": 30,
            "SEK": 300,
            "DKK": 220
          },
          "rewardPercentage": 0.09
        }
      ],
      "pointExpiryDays": 182
    }
  },
  mutations: {
    // SET_DATA(state, { addClubInfo }) {
    //   state.addClubInfo = addClubInfo
    // },
  },
  actions: {},
  getters: {}
};
