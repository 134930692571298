export default {
  namespaced: true,
  state: {
    authReady: false,
    user: null,
    accounts: [],
    accountId: null,
    preferedAccountId: null,
    referalToken: null,
    loginLinkEmail: null,
  },
  mutations: {
    SET_AUTH_READY(state, value) {
      state.authReady = value;
    },
    SET_USER_DATA(state, user) {
      state.user = user;
    },
    SET_ACCOUNTS(state, accounts) {
      state.accounts.splice(0, state.accounts.length, ...accounts)
    },
    SET_ACCOUNT(state, accountId) {
      const index = state.accounts.some((i) => i.accountId === accountId)
      if (index > -1) state.accountId = accountId;
    },
    SET_PREFERED_ACCOUNT(state, accountId) {
      state.preferedAccountId = accountId;
    },
    CLEAR_ACCOUNT(state) {
      state.accountId = null;
    },
    SET_REFERAL_TOKEN(state, referalToken) {
      state.referalToken = referalToken;
    },
    SET_LOGIN_LINK_EMAIL(state, email) {
      state.loginLinkEmail = email;
    }
  },
  actions: {
    authSignIn({ commit }, user) {
      commit('SET_USER_DATA', user);
      gtag('config', 'G-C395FKQR6P', {
        'user_id': user.uid
      });
    },
    signOut({ commit }) {
      commit('SET_USER_DATA', null);
      commit('CLEAR_ACCOUNT', null);
      commit('SET_ACCOUNTS', []);
    },
    getReferral({ commit }) {
      const urlParams = new URLSearchParams(window.location.search);
      const referalToken = urlParams.get('referalToken');
      if (referalToken) commit('SET_REFERAL_TOKEN', referalToken);
    },
    selectAccount({ state, commit }, accountId) {
      const index = state.accounts.some((i) => i.accountId === accountId)
      if (index > -1) {
        commit('SET_ACCOUNT', accountId)
        commit('SET_PREFERED_ACCOUNT', accountId) 
      }
    }
  },
  getters: {
    authenticated: state => Boolean(state.user),
    multipleAccounts: (state, getters) => getters.authenticated && state.accounts.length > 1,
    loggedIn: (state, getters) => getters.authenticated && state.accountId,
    account: state => state.accounts.find(i => i.accountId === state.accountId),
    agreements: (_s, getters) => (getters.account || {}).agreements || [],
  },
};
