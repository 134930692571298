import axios from "axios";
import store from "../store";
import { getIdToken } from "./auth";
import rollbar from "./rollbar";

const api = axios.create({
  baseURL: "https://api.addnorth.com/api/",
});

// api.interceptors.request.use(
//   async (config) => {
//     const token = await getIdToken();
//     if (token) {
//       // config.headers["Authorization"] = `Bearer ${token}`;
//       // config.headers["account-id"] = store.state.account.accountId;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    let message = "Unknown error";
    let requestId = null;
    if (error.response && error.response.data && error.response.data.message) {
      message = Array.isArray(error.response.data.message)
        ? error.response.data.message.join(". ")
        : error.response.data.message;
      requestId = error.response.data.requestId;
    } else if (error.response && error.response.statusText) {
      message = error.response.statusText;
      requestId = error.response.data ? error.response.data.requestId : null;
    } else {
      message = "Server did not respond";
    }
    rollbar.error(message, { error });
    return Promise.reject(error);
  }
);

export default api;
