/* eslint-disable no-console */
import "./services/rollbar";
import Vue from "vue";
import PortalVue from "portal-vue";
import VueJsonLD from "vue-jsonld";
import Vuelidate from "vuelidate";
import VueMeta from "vue-meta";
import VueCurrencyFilter from "./plugins/currency";
import App from "./App.vue";
import Timestamp from "./plugins/timestamp";
// import './registerServiceWorker';
import store from "./store";
import i18n from "./plugins/i18n";
import router from "./router";
import * as api from "./services/api";
import { loadAuth, unloadAuth } from "./services/auth";
import "./main.css";
import api2 from "@/services/api2";

Vue.prototype.$api = api;
Vue.prototype.$api2 = api2;

Vue.use(VueMeta, {
  ssrAppId: 1,
});
Vue.use(VueCurrencyFilter);
Vue.use(Timestamp);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(VueJsonLD);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
  beforeCreate() {
    if (!window._PRERENDER_DATA) {
      // Get URL search params
      store.dispatch("store/getAffiliate");
      store.dispatch("account/getReferral");
    }
  },
  mounted() {
    // Messages
    if (!window._PRERENDER_DATA) {
      loadAuth();
      this.$store.dispatch("shared/load");
      this.$nextTick(() => {
        store.dispatch("shared/sendAnalytics", { event: "user_engagement" });
      });
    }
  },
  beforeDestroy() {
    unloadAuth();
  },
}).$mount("#app");

if (window._PRERENDER_DATA) {
  document.addEventListener("DOMContentLoaded", () => {
    document.dispatchEvent(new Event("x-app-rendered"));
  });
}
