<template>
  <div
    id="app"
    class="relative"
    :class="{
      'raised-menu': raisedMenu,
    }"
  >
    <router-view />
    <portal-target name="modals" />
    <AccountSelectorModal />
  </div>
</template>
<script>
import { sites } from "./internationalization";
import AccountSelectorModal from "./components/account/AccountSelectorModal.vue";

export default {
  name: "app",
  components: {
    AccountSelectorModal,
  },
  data() {
    return {
      raisedMenu: this.$router.currentRoute.meta.raisedMenu,
    };
  },
  metaInfo() {
    // Title
    let title = null;
    if (this.$route.meta.title) title = this.$route.meta.title;
    if (!title && this.$te(`meta.${this.$route.name}.title`)) {
      title = this.$t(`meta.${this.$route.name}.title`);
    }
    if (!title) {
      title = this.$t("meta.home.title");
    }
    // Description
    let description = null;
    if (this.$route.meta.description) description = this.$route.meta.description;
    if (!description && this.$te(`meta.${this.$route.name}.description`))
      description = this.$t(`meta.${this.$route.name}.description`);
    if (!description) description = this.$t("meta.home.description");

    const meta = {
      title,
      titleTemplate: "%s - add:north",
      meta: [
        // TITLE
        {
          vmid: "ogtitle",
          name: "og:title",
          property: "og:title",
          content: `${title} - add:north`,
        },
        {
          vmid: "ogimagealt",
          name: "og:image:alt",
          property: "og:image:alt",
          content: `${title} - add:north`,
        },
        {
          vmid: "twittertitle",
          name: "twitter:title",
          property: "twitter:title",
          content: `${title} - add:north`,
        },
        // Description
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "ogdescription",
          name: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "twitterdescription",
          name: "twitter:description",
          property: "twitter:description",
          content: description,
        },
        // Other OG
        {
          vmid: "fbappid",
          name: "fb:app_id",
          property: "fb:app_id",
          content: "535673976796766",
        },
        {
          vmid: "ogsitename",
          name: "og:site_name",
          property: "og:site_name",
          content: "add:north",
        },
        {
          vmid: "ogtype",
          name: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          vmid: "ogurl",
          name: "og:url",
          property: "og:url",
          content: `https://addnorth.${process.env.VUE_APP_DOMAIN}${this.$route.fullPath}`,
        },
        {
          vmid: "ogimage",
          name: "og:image",
          property: "og:image",
          content:
            "https://storage.googleapis.com/addnorth-com-public/images/logo_liggande_og_image.png",
        },
        {
          vmid: "twitterimage",
          name: "twitter:image",
          property: "twitter:image",
          content:
            "https://storage.googleapis.com/addnorth-com-public/images/logo_liggande_og_image.png",
        },
        {
          vmid: "twittercard",
          name: "twitter:card",
          property: "twitter:card",
          content: "summary",
        },
        {
          vmid: "twittersite",
          name: "twitter:site",
          property: "twitter:site",
          content: "@addnorth3d",
        },
      ],
      // Alternate languages
      link: sites
        .filter(({ excludeMeta, languageTranslated }) => !excludeMeta && languageTranslated)
        .reduce((c, { domain, hreflang }) => {
          c.push({
            rel: "alternate",
            hreflang: hreflang,
            href: `https://addnorth.${domain}${this.$route.fullPath}`,
          });
          if (domain === "com") {
            c.push({
              rel: "alternate",
              hreflang: "x-default",
              href: `https://addnorth.${domain}${this.$route.fullPath}`,
            });
          }
          return c;
        }, []),
    };
    // Locale if not english
    if (process.env.VUE_APP_LANGUAGE !== "en") {
      meta.meta.push({
        vmid: "oglocale",
        name: "og:locale",
        property: "og:locale",
        content: "@addnorth3d",
      });
    }
    // No index
    if (this.$route.meta && this.$route.meta.disallow) {
      meta.meta.push({
        property: "robots",
        content: "noindex",
        vmid: "noindex",
      });
    }
    return meta;
  },
  mounted() {
    this.$nextTick(() => {
      const body = document.querySelector("body");
      body.dataset.loaded = "true";
    });
  },
  watch: {
    $route(to) {
      this.raisedMenu = to.meta.raisedMenu;
    },
  },
};
</script>
