export default {
  namespaced: true,
  state: {
    products: [],
  },
  mutations: {
    async ADD_TO_CART(state, product) {
      const index = state.products.findIndex((i) => i.sku === product.sku);
      if (index >= 0) {
        state.products.splice(index, 1, product);
      } else {
        state.products.push(product);
      }
    },
    REMOVE_FROM_CART(state, id) {
      const index = state.products.findIndex((i) => i.id === id);
      if (index >= 0) {
        state.products.splice(index, 1);
      }
    },
    CLEAR_CART(state) {
      state.products.splice(0, state.products.length);
    },
  },
  getters: {
    skuInCart: (state) => (sku) => state.products.find((i) => i.sku === sku),
    qtySkuInCart: (_s, getters) => (sku) => {
      if (!sku) return 0;
      const product = getters["skuInCart"](sku);
      return !product ? 0 : product.quantity || 0;
    },
    itemsInCart: (state) => state.products.reduce((c, { quantity }) => c + quantity, 0),
    toCheckout: (state) => state.products.map(({ id, sku, quantity }) => ({ id, sku, quantity })),
  },
};
