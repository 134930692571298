import Vue from 'vue';
import VueI18n from 'vue-i18n';
const globalVariables = require('./globalVariables.json')
Vue.use(VueI18n);

const messages = {
  en: require('./translations/en.json')
}

if (process.env.VUE_APP_LANGUAGE !== 'en') {
  messages[process.env.VUE_APP_LANGUAGE] = require(`./translations/${process.env.VUE_APP_LANGUAGE}.json`)
}

class CustomFormatter {
  interpolate (message, values) {
    if (typeof message === 'string' && message && message !== '') {
      const regexp = /\[{3}(.*?)\]{3}/g;
      const matches = [...message.matchAll(regexp)];
      for(const match of matches) {
        if (values && values[match[1]]) {
          message = message.replace(match[0], values[match[1]])
        } else if (globalVariables[match[1]]) {
          message = message.replace(match[0], globalVariables[match[1]])
        } else {
          message = message.replace(match[0], match[1])
        }
      }
    }
    return [message]
  }
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_LANGUAGE,
  fallbackLocale: 'en',
  messages,
  escapeParameterHtml: true,
  formatter: new CustomFormatter()
});

document.querySelector('html').setAttribute('lang', process.env.VUE_APP_LANGUAGE);

export default i18n;
