<template>
  <div class="px-4">
    <h3 class="mb-2 text-center font-medium text-gray-800 dark:text-white" v-text="$t('home.customerSays')" />
    <div class="elfsight-app-2a1306c4-2226-4e0a-a360-c3ccecab4299" />
  </div>
</template>
<script>
export default {
  name: 'Reviews',
};
</script>