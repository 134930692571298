<template>
  <div>
    <div class="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        name="darkSelector"
        id="darkSelector"
        class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 outline-none appearance-none cursor-pointer"
        v-model="showWithVat"
      />
      <label for="darkSelector" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-700 cursor-pointer" />
    </div>
    <label for="darkSelector" v-text="$t('common.dark')" />
  </div>
</template>
<script>

export default {
  name: 'DarkSelector',
  computed: {
    showWithVat: {
      set(dark) {
        this.$store.commit('shared/SET_DARK', dark)
      },
      get() {
        return this.$store.state.shared.dark;
      },
    },
  },
};
</script>
<style scoped>
.toggle-checkbox:checked {
  @apply right-0;
  @apply border-gray-300;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-gray-300;
}
.dark .toggle-checkbox:checked {
  @apply border-gray-600;
}
.dark .toggle-checkbox:checked + .toggle-label {
  @apply bg-gray-700;
}
</style>