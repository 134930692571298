/* eslint-disable no-param-reassign */
import { formatMoney, toFixed } from 'accounting-js';

const currencyFormats = {
  SEK: {
    symbol: 'SEK',
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
  EUR: {
    symbol: '€',
    format: '%s %v',
    decimal: '.',
    thousand: ',',
  },
  USD: {
    symbol: '$',
    format: '%s %v',
    decimal: '.',
    thousand: ',',
  },
  GBP: {
    symbol: '£',
    format: '%s %v',
    decimal: '.',
    thousand: ',',
  },
  DKK: {
    symbol: 'DKK',
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
  NOK: {
    symbol: 'NOK',
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
};

const CurrencyFilter = {
  install(Vue) {

    const format = (price, { currency, discount = 0, precision = 0, quantity = 1, showWithVat }) => {
      if (typeof price ==='undefined' || price === null || Number.isNaN(price) || !currency || typeof showWithVat === 'undefined' || showWithVat === null) return 'N/A';
      let value = price
      if (showWithVat) value = Math.round(value * 1.25);
      if (discount > 0) value = Math.round(value * (1 - discount / 100));
      value *= quantity;
      return formatMoney(toFixed(value / 100, 2), { ...currencyFormats[currency], precision });
    };

    Vue.filter('currency', format);
    Vue.prototype.$c = format;
  },
};

export default CurrencyFilter;
