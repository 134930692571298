import imgixClient from "./imgixClient";

export default class Article {
  content = null;
  slug = null;
  cover = null;
  description = null;
  title = null;
  tags = [];
  type = null;
  jsonld = null;
  meta = null;

  constructor({ content, ...article }) {
    Object.assign(this, article, {
      publishedDateShort: article.publishedDate
        ? new Date(article.publishedDate).toISOString().slice(0, 10)
        : null,
      content: content ? content.content : null,
    });

    if (
      this._snippetResult &&
      this._snippetResult.text &&
      this._snippetResult.text.value &&
      this._snippetResult.text.matchLevel !== "none"
    ) {
      this.description = `...${this._snippetResult.text.value}...`;
    }

    if (this._highlightResult && this._highlightResult.title && this._highlightResult.title.value) {
      this.title = this._highlightResult.title.value;
    }

    if (this._highlightResult && this._highlightResult.tags) {
      this.tags = this._highlightResult.tags.map(({ value }) => value);
    }

    if (this._highlightResult && this._highlightResult.type && this._highlightResult.type.value) {
      this.type = this._highlightResult.type.value;
    }

    if (this.cover) {
      this.cover = {
        src: this.cover.imgixPath || "placeholder.svg",
        alt: this.cover.description || null,
      };
    }

    const image = [];
    if (this.content) {
      image.push(...new Set(this.findImageUrls(this.content)));
    }

    this.jsonld = {
      "@context": "http://schema.org/",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://addnorth.${process.env.VUE_APP_DOMAIN}/knowledge/articles/${this.slug}`,
      },
      headline: this.title,
      image,
      datePublished: new Date(this.publishedDate).toISOString(),
      dateModified: new Date(this.publishedDate).toISOString(),
      author: {
        "@type": "Organization",
        name: "Add North 3D AB",
        logo: {
          "@type": "ImageObject",
          url: "https://storage.googleapis.com/addnorth-com-public/images/logo_liggande.png",
        },
      },
      publisher: {
        "@type": "Organization",
        name: "Add North 3D AB",
        logo: {
          "@type": "ImageObject",
          url: "https://storage.googleapis.com/addnorth-com-public/images/logo_liggande.png",
        },
      },
    };

    this.meta = {
      title: this.title,
      meta: [
        {
          vmid: "ogtitle",
          name: "og:title",
          property: "og:title",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "ogimagealt",
          name: "og:image:alt",
          property: "og:image:alt",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "ogurl",
          name: "og:url",
          property: "og:url",
          content: `https://addnorth.${process.env.VUE_APP_DOMAIN}/knowledge/articles/${this.slug}`,
        },
        {
          vmid: "ogtype",
          name: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twittertitle",
          name: "twitter:title",
          property: "twitter:title",
          content: `${this.title} - add:north`,
        },
        {
          vmid: "description",
          name: "description",
          content: this.description,
        },
        {
          vmid: "ogdescription",
          name: "og:description",
          property: "og:description",
          content: this.description,
        },
        {
          vmid: "twitterdescription",
          name: "twitter:description",
          property: "twitter:description",
          content: this.description,
        },
        {
          vmid: "articlepublished_time",
          name: "article:published_time",
          property: "article:published_time",
          content: new Date(article.publishedDate).toISOString(),
        },
      ],
    };
    if (this.tags && this.tags.length) {
      this.meta.meta.push({
        vmid: "articletag",
        name: "article:tag",
        property: "article:tag",
        content: this.tags.join(","),
      });
    }
    if (this.cover) {
      this.meta.meta.push(
        {
          vmid: "ogimage",
          name: "og:image",
          property: "og:image",
          content: imgixClient.buildURL(this.cover.src, {
            auto: "format,compress",
            ch: "DPR",
            q: 45,
            w: 320,
            fit: "clip",
          }),
        },
        {
          vmid: "twitterimage",
          name: "twitter:image",
          property: "twitter:image",
          content: imgixClient.buildURL(this.cover.src, {
            auto: "format,compress",
            ch: "DPR",
            q: 45,
            w: 320,
            fit: "clip",
          }),
        }
      );
    } else if (image.length) {
      this.meta.meta.push(
        {
          vmid: "ogimage",
          name: "og:image",
          property: "og:image",
          content: imgixClient.buildURL(image[0], {
            auto: "format,compress",
            ch: "DPR",
            q: 45,
            w: 320,
            fit: "clip",
          }),
        },
        {
          vmid: "twitterimage",
          name: "twitter:image",
          property: "twitter:image",
          content: imgixClient.buildURL(image[0], {
            auto: "format,compress",
            ch: "DPR",
            q: 45,
            w: 320,
            fit: "clip",
          }),
        }
      );
    }
  }

  findImageUrls(c = []) {
    const ids = [];
    c.forEach((i) => {
      if (i.type === "image") ids.push(i.attrs.src);
      if (i.content && i.content.length) ids.push(...this.findImageUrls(i.content));
    });
    if (this.cover && this.cover.src) ids.push(this.cover.src);
    return ids;
  }
}
