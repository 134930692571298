import { v4 as uuidv4 } from "uuid";

const getFBPCookie = () => document.cookie.match("(^|;)\\s*_fbp\\s*=\\s*([^;]+)")?.pop() || "";

export default {
  namespaced: true,
  state: {
    agreedCookies: null,
    dark: false,
    fbc: null,
    bID: null,
  },
  mutations: {
    AGREE_COOKIES(state, agreedCookies) {
      state.agreedCookies = agreedCookies;
    },
    SET_DARK(state, dark) {
      state.dark = dark;
    },
    SET_B_ID(state, bID) {
      state.bID = bID;
    },
    SET_FBC(state, fbc) {
      state.fbc = fbc;
    },
  },
  actions: {
    setSession({ state, commit }) {
      if (!state.bID) {
        const bID = getFBPCookie().split(".").pop() || Math.floor(Math.random() * 10000000000);
        commit("SET_B_ID", bID);
      }
    },
    setFbpFromSession({ state }) {
      if (!getFBPCookie()) {
        document.cookie = `_fbp=fb.1.${Date.now()}.${state.bID}; Domain=${
          window.location.hostname
        }; SameSite=Lax; Path=/; Max-Age=${90 * 24 * 60 * 60}; Secure`;
      }
    },
    setFbClid({ commit }, fbclid) {
      const fbc = `fb.1.${Date.now()}.${fbclid}`;
      commit("SET_FBC", fbc);
    },
    agreedCookies({ commit, dispatch }) {
      commit("AGREE_COOKIES", true);
      dispatch("setFbpFromSession");
      gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
      gtag("event", "consent");
      fbq("consent", "grant");
    },
    load({ state, dispatch }) {
      if (state.agreedCookies) {
        dispatch("setFbpFromSession");
        gtag("consent", "update", {
          ad_storage: "granted",
          analytics_storage: "granted",
        });
        gtag("event", "consent");
        fbq("consent", "grant");
      } else {
        gtag("event", "revoke");
      }
      dispatch("loadHubspot");
    },
    loadHubspot({ state }) {
      const w = window.matchMedia("(min-width: 1024px)");
      if (!w.matches || !state.agreedCookies || document.getElementById("hs-script-loader")) return;
      const script = document.createElement("script");
      script.src = "//js-na1.hs-scripts.com/7263663.js";
      script.id = "hs-script-loader";
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    sendAnalytics({ state, dispatch, rootState }, { event, ...parameters }) {
      dispatch("setSession");
      const eventId = uuidv4();
      if (
        [
          "add_to_cart",
          "begin_checkout",
          "add_shipping_info",
          "add_payment_info",
          "purchase",
        ].includes(event) &&
        !state.agreedCookies
      ) {
        dispatch("agreedCookies");
      }
      let page_title = decodeURI(window.location.pathname.toLowerCase())
        .split("/")
        .filter((i) => !!i);
      page_title.unshift("home");
      page_title = page_title.join(" | ");
      const userId = rootState.account.user ? rootState.account.user.uid : null;
      const data = {
        // External ID Google
        user_id: userId,
        // External ID FB
        "x-fb-ud-external_id": userId,
        // Facebook content type
        "x-fb-cd-content_type": "product",
        content_type: "product",
        // Facebook FBC
        "x-fb-ck-fbc": state.fbc,
        // Facebook FBP
        "x-fb-ck-fbp": getFBPCookie() || `fb.1.${Date.now()}.${state.bID}`,
        // Default
        currency: rootState.store.currency,
        first_party_collection: true,
        // Event specific
        event_id: eventId,
        event_time: Math.round(new Date().getTime() / 1000),
        page_title,
        ...parameters,
      };
      gtag("event", event, data);
      dispatch("sendPixel", { event, ...data });
    },
    sendPixel({ rootState }, data) {
      const {
        event,
        event_id: eventID,
        items = [],
        value,
        search_term: search_string,
        user_data: {
          email_address,
          phone_number,
          address: { first_name, last_name, city, state, zip, country } = {
            email_address: null,
            phone_number: null,
            first_name: null,
            last_name: null,
            city: null,
            state: null,
            zip: null,
            country: null,
          },
        } = {
          email_address: null,
          phone_number: null,
          first_name: null,
          last_name: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        },
        user_id: external_id,
      } = data;
      const EVENT_MAPPINGS = {
        add_shipping_info: "AddPaymentInfo",
        add_payment_info: "AddPaymentInfo",
        add_to_cart: "AddToCart",
        add_to_wishlist: "AddToWishlist",
        page_view: "PageView",
        purchase: "Purchase",
        search: "Search",
        begin_checkout: "InitiateCheckout",
        generate_lead: "Lead",
        view_item: "ViewContent",
        sign_up: "CompleteRegistration",
      };
      if (EVENT_MAPPINGS[event]) {
        const fbData = {
          currency: rootState.store.currency,
          contents: items.map((i) => ({
            id: i.item_id,
            item_price: i.price,
            quantity: i.quantity,
          })),
          value,
          num_items: items.length,
          search_string,
          em: !!email_address ? email_address.toLowerCase() : null,
          fn: !!first_name ? first_name.toLowerCase() : null,
          ln: !!last_name ? last_name.toLowerCase() : null,
          ph: phone_number,
          external_id,
          ct: !!city ? city.toLowerCase() : null,
          st: !!state ? state.toLowerCase() : null,
          zp: !!zip ? zip.toLowerCase() : null,
          country: !!country ? country.toLowerCase() : null,
        };
        if (event === "sign_up") fbData.status = true;
        fbq("track", EVENT_MAPPINGS[event], fbData, { eventID });
      }
    },
  },
  getters: {
    encodeUrl: () => (path, query) =>
      `${encodeURI(`${path ? path : "/"}`)}${
        query ? `?${new URLSearchParams(query).toString()}` : ""
      }`,
    cookiesSelected: (state) => [true, false].includes(state.agreedCookies),
  },
};
