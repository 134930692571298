import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";
import account from "./modules/account";
import shared from "./modules/shared";
import store from "./modules/store";
import cart from "./modules/cart";
import content from "./modules/content";
import resources from "./modules/resources";
import checkout from "./modules/checkout";
import api from "@/services/api2";
import Variation from "@/services/variation2";

Vue.use(Vuex);

const storageKey = "@addnorth";

const pathsToPersist = [
  // Module, key, mutation to watch, required cookie accept
  ["checkout", "country", "checkout/SET_COUNTRY", false],
  ["checkout", "company", "checkout/SET_COMPANY", false],
  ["store", "currency", "store/SET_CURRENCY", false],
  ["store", "showWithVat", "store/SET_SHOW_WITH_VAT", false],
  ["store", "affiliates", "store/SET_AFFILIATE", false],
  ["shared", "agreedCookies", "shared/AGREE_COOKIES", false],
  ["shared", "dark", "shared/SET_DARK", false],
  ["shared", "fbc", "shared/SET_FBC", false],
  ["shared", "bID", "shared/SET_B_ID", false],
  ["cart", "products", "cart/ADD_TO_CART", false],
  ["cart", "products", "cart/CHANGE_CART", false],
  ["cart", "products", "cart/REMOVE_FROM_CART", false],
  ["cart", "products", "cart/CLEAR_CART", false],
  ["account", "referalToken", "account/SET_REFERAL_TOKEN", false],
  ["account", "preferedAccountId", "account/SET_PREFERED_ACCOUNT", false],
  ["account", "loginLinkEmail", "account/SET_LOGIN_LINK_EMAIL", false],
  ["content", "seenMessages", "content/SEEN_MESSAGE", true],
  ["content", "wishList", "content/WISH_PRODUCT", true],
];

const getProduct = async (store, id, quantity) => {
  try {
    const { data } = await api.get(`catalog/variations/${id}`, {
      params: {
        language: process.env.VUE_APP_LANGUAGE,
      },
    });
    const product = new Variation(store, data, quantity, true);
    return product;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 404) {
      rollbar.error(`Could not load cart sku (${id}) not found in catalog`);
    } else {
      rollbar.error(error);
    }
    return null;
  }
};

const saveToLocalStorage = async (store) => {
  // Hydrate on start
  const value = localStorage.getItem(storageKey);
  if (value) {
    const data = JSON.parse(value);
    const copyState = { ...store.state };
    // Check cart products
    if (data && data.cart && data.cart.products && data.cart.products.length) {
      data.cart.products.forEach((product) => {
        store.commit("cart/ADD_TO_CART", product);
      });
      data.cart.products.forEach(({ id, quantity }) => {
        getProduct(store, id, quantity).then((product) => {
          if (
            !product ||
            (!product.availability.canBeOrdered && product.availability.maxQtyToOrder <= 0)
          ) {
            store.commit("cart/REMOVE_FROM_CART", id);
          } else if (!product.availability.canBeOrdered && product.availability.maxQtyToOrder > 0) {
            product.quantity = product.availability.maxQtyToOrder;
            store.commit("cart/ADD_TO_CART", product);
          }
        });
      });
    }
    for (let module in data) {
      for (let key in data[module]) {
        if (typeof data[module][key] !== "undefined" && data[module][key] !== null) {
          if (module === "cart" && key === "products") {
            continue;
          } else if (Array.isArray(data[module][key])) {
            if (!copyState[module][key]) copyState[module][key] = [];
            copyState[module][key].push(...data[module][key]);
          } else {
            copyState[module][key] = data[module][key];
          }
        }
      }
    }
    store.replaceState(copyState);
  }

  // Persist
  const persist = (state) => {
    // Filter paths
    let stateToPersist = pathsToPersist.filter((paths) => !paths[3] || state.shared.agreedCookies);
    // Keep unique
    stateToPersist = [
      ...new Set(stateToPersist.map(([module, key]) => `${module}/${key}`)),
    ].map((i) => i.split("/"));
    // Persist
    stateToPersist = stateToPersist.reduce((c, [module, key]) => {
      if (!c[module]) c[module] = {};
      c[module][key] = state[module][key];
      return c;
    }, {});
    localStorage.setItem(storageKey, JSON.stringify(stateToPersist));
  };

  // Commits to watch
  const watchCommits = pathsToPersist.map((i) => i[2]);

  store.subscribe(({ type }, state) => {
    if (watchCommits.includes(type)) persist(state);
  });
};

const ANstore = new Vuex.Store({
  modules: {
    account,
    shared,
    store,
    cart,
    content,
    resources,
    checkout,
  },
  plugins: [
    saveToLocalStorage,
    // Share across tabs
    createMutationsSharer({
      predicate: (_mutation, _state) => true,
    }),
  ],
});

export default ANstore;
