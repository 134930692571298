<template>
  <ProductCard
    style="height: 107px"
    class="w-full relative pr-2 shadow-sm border z-10 hover:bg-gray-100 rounded-md flex items-center dark:bg-gray-700 dark:hover:bg-gray-600"
    :product="product"
    :index="index"
    :listId="listId"
    :listName="listName"
  >
    <template
      v-slot="{
        product: {
          thumbnail: {
            src,
            description,
            bgColor
          },
          texts: {
            title
          },
          bullets
        },
        displayLabels,
      }"
    >
    <!-- class="relative mr-2 px-2 flex-shrink-0 flex items-center" -->
      <div
        class="p-1 mr-1 flex-shrink-0 w-full flex justify-center items-center"
        style="width: 100px; height: 87.5px"
        :style="!!showThumbnailBgColor && !!bgColor ? `background: ${bgColor}` : ''"
      >
        <img
          :src="image(src)"
          class="object-contain"
          :alt="`Add North 3D filament ${description}`"
        />
        <div
          v-for="({ text, classes }, index) in displayLabels"
          :key="text"
          class="absolute left-0 top-0"
          :style="`margin-top: ${index * 1.3}rem`"
        >
          <div
            class="h-0 relative"
            :class="classes"
            :style="`width: ${6 - index}rem; border-top-width: 20px; border-right: 10px solid transparent;`"
          >
            <div
              style="top: -21px;"
              class="text-xxs absolute inset-0 py-1 pl-2 overflow-visible font-medium uppercase"
              v-text="text"
            />
          </div>
        </div>
      </div>
      <div class="flex-shrink flex-grow whitespace-nowrap truncate overflow-hidden">
        <h5 class="font-medium text-sm whitespace-nowrap truncate overflow-hidden" v-html="title" />
        <div v-if="bullets.length" class="mb-2 flex-shrink flex-grow">
          <div
            class="flex flex-shrink items-center text-sm"
            v-for="(bullet, index) in bullets"
            :key="`${index}${bullet}`"
          >
            <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
              <path d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z" />
            </svg>
            <p class="w-full whitespace-nowrap truncate overflow-hidden" style="min-width: 0" v-html="bullet" />
          </div>
        </div>
      </div>
    </template>
  </ProductCard>
</template>
<script>
import ProductCard from './ProductCard.vue';
import imgixClient from '@/services/imgixClient';

export default {
  name: 'ProductCardSearch',
  components: {
    ProductCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showThumbnailBgColor: {
      type: Boolean,
      default: false
    },
    listName: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  methods: {
    image(path) {
      return imgixClient.buildURL(path, { auto: 'format,compress', ch: 'DPR', q: 45, h: 105, fit: 'clip' })
    }
  }
};
</script>