<template>
  <div class="flex-grow mx-10" v-click-outside="closeMenu">
    <div class="flex w-full">
      <div class="styled-form inline-block relative md:max-w-xs w-full">
        <input
          id="search"
          name="search"
          class="w-full rounded-lg"
          type="text"
          placeholder=" "
          v-model="query"
          v-on:focus="openMenu"
          v-on:click="openMenu"
        />
        <label
          for="search"
          class="floating-label truncate"
          v-text="$t('common.searchProductArticle')"
        />
        <div v-if="loading" class="absolute right-0 top-0 bottom-0 flex items-center">
          <div class="spinner mr-2 w-6 h-6 text-gray-700 dark:text-white" />
        </div>
      </div>
    </div>
    <div
      v-if="menuOpen"
      class="w-11/12 xxl:w-10/12 xxxl:w-9/12 bg-white z-10 absolute md:left-50 md:transform md:-translate-x-1/2 md:top-100 dark:bg-gray-900 dark:text-white rounded-b-lg shadow-lg"
    >
      <div class="px-8 pt-6 pb-12">
        <h4 class="font-medium mb-4 text-lg" v-text="$tc('store.products.product', 2)" />
        <div v-if="products.length">
          <div class="grid grid-cols-2 xl:grid-cols-3 gap-2">
            <ProductCardSearch
              v-for="(product, index) in products"
              :key="product.objectID"
              :product="product"
              :index="index"
              :showThumbnailBgColor="true"
              listId="Search"
              listName="search"
            />
          </div>
          <div class="mt-6">
            <router-link
              class="main-button"
              :to="`/shop?query=${localQuery}`"
              v-text="$t('shop.viewProductResults', { nbHits: nbProductHits })"
            />
          </div>
        </div>
        <div v-else class="ml-4" v-text="'No products found'" />
        <h4 class="font-medium mt-8 mb-4 text-lg" v-text="$t('meta.knowledge.title')" />
        <div v-if="articles.length">
          <div class="grid grid-cols-2 xl:grid-cols-3 gap-2">
            <KnowledgeCardSearch
              v-for="article in articles"
              :key="article.objectID"
              :article="article"
            />
          </div>
          <div class="mt-6">
            <router-link
              class="main-button"
              :to="`/knowledge/browse?q=${localQuery}`"
              v-text="$t('shop.viewArticleResults', { nbHits: nbArticleHits })"
            />
          </div>
        </div>
        <div v-else class="ml-4" v-text="'No articles found'" />
      </div>
    </div>
  </div>
</template>
<script>
import searchClient from "@/services/algolia";
import ClickOutside from "vue-click-outside";
import ProductCardSearch from "../store/ProductCardSearch.vue";
import KnowledgeCardSearch from "../knowledge/KnowledgeCardSearch.vue";
import Article from "@/services/article.js";
import Variation from "@/services/variation2.js";
import imgixClient from "../../services/imgixClient";

export default {
  name: "Search",
  directives: {
    ClickOutside,
  },
  components: {
    ProductCardSearch,
    KnowledgeCardSearch,
  },
  data() {
    return {
      sweden: imgixClient.buildURL("other/sweden.png", {
        auto: "format,compress",
        ch: "DPR",
        q: 45,
        h: 43,
        fit: "clip",
      }),
      delay: 200,
      timeoutId: null,
      loading: false,
      localQuery: this.$router.currentRoute.query.query || "",
      resolving: false,
      menuOpen: false,
      products: [],
      nbProductHits: 0,
      articles: [],
      nbArticleHits: 0,
    };
  },
  computed: {
    query: {
      get() {
        return this.localQuery;
      },
      set(query) {
        this.loading = true;
        this.localQuery = query;
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.search, this.delay);
      },
    },
  },
  methods: {
    search() {
      if (!this.menuOpen) this.openMenu();
      searchClient
        .search([
          {
            indexName: `variations`,
            query: this.localQuery,
            params: {
              page: 0,
              hitsPerPage: 6,
              filters: [
                `language:${process.env.VUE_APP_LANGUAGE}`,
                `environment:${process.env.VUE_APP_ALGOLIA_ENV || process.env.VUE_APP_NODE_ENV}`,
                "active:true",
              ].join(" AND "),
              distinct: true,
            },
          },
          {
            indexName: "articles",
            query: this.localQuery,
            params: {
              page: 0,
              hitsPerPage: 3,
              filters: [
                `language:${process.env.VUE_APP_LANGUAGE}`,
                `environment:${process.env.VUE_APP_ALGOLIA_ENV || process.env.VUE_APP_NODE_ENV}`,
                "published:true",
              ].join(" AND "),
              distinct: true,
            },
          },
        ])
        .then(this.parseResults)
        .then(() => {
          this.loading = false;
        })
        .then(this.sendAnalytics)
        .catch((error) => {
          if (window.Rollbar) window.Rollbar.error(`Search error: ${error.message}`, { error });
        });
    },
    parseResults(data) {
      const {
        results: [
          { hits: products, nbHits: nbProductHits },
          { hits: articles, nbHits: nbArticleHits },
        ],
      } = data;
      this.nbProductHits = nbProductHits;
      this.nbArticleHits = nbArticleHits;
      this.products.splice(
        0,
        this.products.length,
        ...products.map((v) => new Variation(this.$store, v))
      );
      this.articles.splice(0, this.articles.length, ...articles.map((h) => new Article(h)));
    },
    sendAnalytics() {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "search",
        search_term: this.localQuery,
      });
    },
    openMenu() {
      if (this.localQuery) this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.closeMenu();
      },
    },
  },
  beforeDestroy() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  },
};
</script>
