<template>
  <router-link
    class="block"
    :to="`/knowledge/articles/${article.slug}`"
  >
    <div
      class="knowledge-card"
      :class="{
        fixed: !niceCard
      }"
    >
      <img
        :src="image(article.cover.src)"
        :alt="article.cover.alt"
        :class="{
          'bg-white': !niceCard
        }"
      />
      <div
        class="flex flex-col overflow-hidden absolute bottom-0.5 left-1 right-1"
        :class="{
          'top-16': niceCard,
          'top-1/2': !niceCard
        }"
      >
        <div class="text-sm font-medium uppercase flex-shrink-0 overlay" v-html="article.type" />
        <h6 class="truncate font-light flex-shrink-0 leading-relaxed overlay" v-html="article.title" />
        <p
          class="text-xs flex-grow flex-shrink overflow-hidden overflow-ellipsis leading-tight mb-1 overlay"
          v-html="article.description"
        />
        <div class="flex justify-between items-end flex-shrink-0">
          <div class="flex flex-shrink-0 overflow-hidden z-10">
            <div
              v-for="tag in article.tags"
              :key="`${article.slug}${tag}`"
              class="text-xs font-medium uppercase rounded-lg px-2 py-1 m-1 bg-gray-200 dark:bg-gray-600"
              v-html="`#${tag}`"
            />
          </div>
          <div v-if="article.onlyEnglish" class="text-xs overlay flex-shrink-0" v-text="$t('common.onlyInEnglish')" />
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import imgixClient from '../../services/imgixClient';

export default {
  name: 'KnowledgeCardDefault',
  props: {
    article: {
      type: Object,
      required: true
    },
  },
  computed: {
    niceCard() {
      return ['Use cases', 'Tips'].includes(this.article.type)
    }
  },
  methods: {
    image(path) {
      return imgixClient.buildURL(path, { auto: 'format,compress', ch: 'DPR', q: 45, w: 320, fit: 'clip' })
    }
  },
  jsonld() {
    if (!this.article || !this.article.jsonld) return {}
    return this.article.jsonld
  },
};
</script>
<style scoped>
.knowledge-card {
  @apply block;
  @apply shadow-lg;
  @apply rounded-lg;
  @apply bg-white;
  @apply relative;
  width: 320px;
  height: 253px;
  @apply ease-out;
  @apply duration-300;
  @apply my-4;
}
*:not(.dark) .knowledge-card {
  @apply text-gray-700;
}
*:not(.dark) .knowledge-card:hover {
  @apply bg-gray-100;
}
.dark .knowledge-card {
  @apply bg-gray-800;
  @apply text-white;
}
.dark .knowledge-card:hover {
  @apply bg-gray-700;
}
.knowledge-card:hover {
  @apply transform;
  @apply translate-y-4;
}
.knowledge-card:before {
  @apply opacity-0;
  content: "";
  @apply absolute;
  @apply inset-0;
  @apply block;
  @apply rounded-lg;
  @apply bg-black;
  @apply bg-opacity-75;
  z-index: 2;
  @apply transition;
  @apply duration-500;
}
.knowledge-card:hover:before {
  @apply opacity-100;
}
.knowledge-card:not(.fixed) .overlay {
  @apply opacity-0;
  @apply transform;
  @apply translate-y-4;
  @apply duration-500;
}
.knowledge-card:hover .overlay {
  @apply transform;
  @apply translate-y-0;
  @apply opacity-100;
  @apply text-white;
  z-index: 3;
}
.knowledge-card img {
  @apply absolute;
  @apply left-0;
  @apply top-0;
  @apply w-full;
  @apply appearance-none;
  @apply outline-none;
}
.knowledge-card:not(.fixed) img {
  @apply object-cover;
  @apply h-full;
  @apply rounded-lg;
}
.knowledge-card.fixed img {
  @apply object-contain;
  @apply h-1/2;
  @apply rounded-t-lg;
}
</style>