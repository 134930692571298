const searchClient = {
  async search(requests) {
    try {
      const response = await fetch("https://addnorth.com/algolia", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (response.status === 400) {
        throw new Error("Invalid request");
      } else {
        throw new Error("Network error");
      }
    } catch (error) {
      console.error(`Algolia error ${error.message}`);
      if (window.Rollbar) window.Rollbar.error(`Algolia error: ${error.message}`, error);
      return Promise.reject();
    }
  },
};

export default searchClient;
