<template>
  <div v-if="knowledgeBase.length" class="pb-12">
    <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
      <Carousel
        :items="knowledgeBase"
        :title="$t('home.articles')"
        :viewAllLink="$store.getters['shared/encodeUrl']('/knowledge/browse/Use cases')"
        :viewAllText="`${$t('common.goTo')} knowledge base`"
        listName="Home Knowledge base"
        listId="home_knowledge_base"
        key="home_knowledge_base"
        type="article"
        class="my-4 mx-6 xl:mx-0"
      >
        <template v-slot:item="{ item, index, listName, listId }">
          <KnowledgeCardDefault
            :key="item.slug"
            :article="item"
            :index="index"
            :listId="listId"
            :listName="listName"
            class="inline-block"
          />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import Carousel from "../store/Carousel.vue";
import KnowledgeCardDefault from "../knowledge/KnowledgeCardDefault.vue";
import Article from "@/services/article.js";

export default {
  name: "KnowledgeBaseFeatured",
  components: {
    Carousel,
    KnowledgeCardDefault,
  },
  data() {
    return {
      knowledgeBase: this.$store.state.content.articles.slice(0, 6).map((h) => new Article(h)),
    };
  },
};
</script>
