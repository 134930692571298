<template>
  <Modal
    :open="open"
    @close="close"
  >
    <div class="bg-white dark:bg-gray-900 dark:text-white rounded-lg shadow-2xl py-8 px-12 flex flex-col items-center space-y-6" style="width: 300px">
      <p class="font-medium text-lg" v-text="$t('site.shopSettings')" />
      <CurrencySelector />
      <LanguageSelector />
      <div class="space-y-4">
        <VatSelector />
        <DarkSelector />
      </div>
      <button
        class="main-button w-full mt-8"
        v-text="$t('common.close')"
        @click="close"
      />
    </div>
  </Modal>
</template>
<script>
import Modal from './Modal.vue';
import VatSelector from '../store/VatSelector.vue';
import DarkSelector from './DarkSelector.vue';
import CurrencySelector from '../store/CurrencySelector.vue';
import LanguageSelector from '../store/LanguageSelector.vue';

export default {
  name: 'ShopSettings',
  components: {
    Modal,
    VatSelector,
    CurrencySelector,
    LanguageSelector,
    DarkSelector,
  },
  computed: {
    open() {
      return this.$store.state.store.showShopSettings;
    },
  },
  methods: {
    close() {
      this.$store.commit('store/SET_SHOW_SHOP_SETTINGS', false);
    },
  },
};
</script>
