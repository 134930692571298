var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProductCard',{staticClass:"block relative mb-1 z-10 shadow-sm hover:shadow-md rounded-md bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700",staticStyle:{"width":"320px","height":"320px"},attrs:{"product":_vm.product,"index":_vm.index,"listId":_vm.listId,"listName":_vm.listName},scopedSlots:_vm._u([{key:"default",fn:function({
      product: {
        thumbnail: { src, description, bgColor },
        texts: { primaryAttribute, secondaryAttribute, remainingAttributes },
        msrp,
        discount,
        bullets,
      },
      displayLabels,
    }){return [_c('div',{staticClass:"relative p-1 w-full flex justify-center items-center",staticStyle:{"height":"128px"},style:(_vm.showThumbnailBgColor && !!bgColor ? `background: ${bgColor}` : '')},[_c('img',{staticClass:"object-contain",staticStyle:{"height":"120px","width":"150px"},attrs:{"src":_vm.image(src),"alt":`Add North 3D filament ${description}`}}),_vm._l((displayLabels),function({ text, classes },index){return _c('div',{key:text,staticClass:"absolute left-0 top-0",style:(`margin-top: ${index * 2}rem`)},[_c('div',{staticClass:"h-0 relative",class:classes,style:(`width: ${
            9 - index
          }rem; border-top-width: 28px; border-right: 10px solid transparent;`)},[_c('div',{staticClass:"text-sm absolute inset-0 py-1 pl-4 overflow-visible font-medium uppercase",staticStyle:{"top":"-29px"},domProps:{"textContent":_vm._s(text)}})])])})],2),_c('div',{staticClass:"px-4"},[_c('div',{staticStyle:{"height":"68px"}},[_c('div',{staticClass:"w-full flex space-x-2 justify-between"},[_c('div',{staticClass:"flex-shrink mr-2 overflow-hidden"},[_c('h4',{staticClass:"font-medium text-lg leading-tight truncate",domProps:{"innerHTML":_vm._s(primaryAttribute)}})]),_c('div',{staticClass:"flex-shrink-0 relative"},[_c('ProductPrice',{staticClass:"absolute right-0 top-0",attrs:{"msrp":msrp,"currency":_vm.$store.state.store.currency,"discount":discount,"showWithVat":_vm.$store.state.store.showWithVat,"quantity":1}})],1)]),_c('h5',{staticClass:"font-light text-base truncate",domProps:{"innerHTML":_vm._s(secondaryAttribute)}}),(remainingAttributes)?_c('h6',{staticClass:"font-medium text-sm truncate",domProps:{"innerHTML":_vm._s(remainingAttributes)}}):_vm._e()]),_c('div',{staticClass:"mb-2",staticStyle:{"height":"63px"}},_vm._l((bullets),function(bullet,index){return _c('div',{key:`${index}${bullet}`,staticClass:"flex flex-shrink items-center text-sm"},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"}})]),_c('p',{staticClass:"w-full whitespace-nowrap truncate overflow-hidden",staticStyle:{"min-width":"0"},domProps:{"innerHTML":_vm._s(bullet)}})])}),0),_c('button',{staticClass:"w-full main-button flex-shrink-0",staticStyle:{"min-width":"100px"},domProps:{"textContent":_vm._s(_vm.$t('shop.view'))}})])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }