<template>
  <Modal
    :open="selectionNeeded"
    :persistent=true
  >
    <AccountSelector class="bg-white p-8 rounded-sm" />
  </Modal>
</template>
<script>
import Modal from '../shared/Modal.vue';
import AccountSelector from './AccountSelector.vue';
export default {
  name: 'AccountSelectorModal',
  components: {
    Modal,
    AccountSelector,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    selectionNeeded() {
      return this.$store.getters['account/multipleAccounts'] && !this.$store.state.account.accountId;
    },
  },
};
</script>
