<template>
  <div class="styled-form w-52">
    <select
      name="languageSelector"
      class="w-full rounded-lg appearance-none"
      placeholder=" "
      id="languageSelector"
      v-model="language"
    >
      <option
        v-for="site in sites"
        :key="site.code"
        :value="site.code"
        v-text="site.name"
        :selected="site.code === language"
      />
    </select>
    <label for="languageSelector" class="floating-label" v-text="$t('languages.language')" />
  </div>
</template>
<script>
import { sites } from '@/internationalization';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      sites: sites.reduce((c, { code, host }) => {
        if (c.some(i => i.code === code)) return c;
        c.push({ code, host, name: this.$t(`languages.${code}`) })
        return c;
      }, [])
    }
  },
  computed: {
    language: {
      set(code) {
        const { host } = this.sites.find((i) => i.code === code)
        window.document.location = `https://${host}${this.$router.currentRoute.fullPath}`
      },
      get() {
        return process.env.VUE_APP_LANGUAGE;
      },
    },
  },
};
</script>
