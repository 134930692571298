<template>
  <router-link :to="product.productLink" @click.native="viewProduct">
    <slot v-bind:product="product" v-bind:displayLabels="displayLabels" />
  </router-link>
</template>
<script>
export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    listName: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayLabels() {
      const displayLabels = [];
      // Latest if active last 200 days
      if (this.product.labels.newProduct)
        displayLabels.push({
          classes: "text-white border-purple-400",
          text: `${this.$t("store.products.newProduct")}!`,
        });
      if (this.product.labels.bestSeller)
        displayLabels.push({
          classes: "border-yellow-400",
          text: this.$tc("store.products.bestSeller", 1),
        });
      if (this.product.labels.staffPick)
        displayLabels.push({
          classes: "text-white border-blue-400",
          text: `${this.$tc("store.products.staffPick", 1)}!`,
        });
      if (this.product.labels.discount > 0)
        displayLabels.push({
          classes: "border-red-400 text-white",
          text: `${this.$t("store.products.sale")} -${this.product.labels.discount}%`,
        });
      return displayLabels;
    },
  },
  methods: {
    viewProduct() {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "select_item",
        item_list_name: this.listName,
        item_list_id: this.listId,
        items: [this.product.googleItem(this.listName, this.listId, this.index)],
      });
    },
  },
  jsonld() {
    return this.product.jsonld;
  },
};
</script>
