const currencies = [
  'SEK',
  'EUR',
  'USD',
  'DKK',
  'NOK',
  'GBP'
];

const sites = [
  {
    code: 'en',
    hreflang: 'en-us',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.com',
    domain: 'com',
    target: 'addnorth-com',
    languageTranslated: true,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'FedEx',
      'UPS',
      'DHL',
    ]
  },
  {
    code: 'sv',
    hreflang: 'sv',
    name: 'Swedish',
    currency: 'SEK',
    showWithVat: true,
    host: 'addnorth.se',
    domain: 'se',
    target: 'addnorth-se',
    languageTranslated: true,
    paymentMethods: [
      'klarna',
      'swish',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'PostNord',
      'DBSchenker'
    ]
  },
  {
    code: 'en',
    hreflang: 'en-gb',
    name: 'English',
    currency: 'GBP',
    showWithVat: true,
    host: 'addnorth.co.uk',
    domain: 'co.uk',
    target: 'addnorth-co-uk',
    languageTranslated: true,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  {
    code: 'en',
    hreflang: 'de',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.de',
    domain: 'de',
    target: 'addnorth-de',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  {
    code: 'en',
    hreflang: 'da',
    name: 'English',
    currency: 'DKK',
    showWithVat: true,
    host: 'addnorth.dk',
    domain: 'dk',
    target: 'addnorth-dk',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  {
    code: 'en',
    hreflang: 'fi',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.fi',
    domain: 'fi',
    target: 'addnorth-fi',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  {
    code: 'en',
    hreflang: 'fr',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.fr',
    domain: 'fr',
    target: 'addnorth-fr',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'FedEx',
    ]
  },
  {
    code: 'en',
    hreflang: 'it',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.it',
    domain: 'it',
    target: 'addnorth-it',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  {
    code: 'en',
    hreflang: 'es',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth.es',
    domain: 'es',
    target: 'addnorth-es',
    languageTranslated: false,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'UPS',
    ]
  },
  // {
  //   code: 'no',
  //   name: 'English',
  //   currency: 'NOK',
  //   showWithVat: false,
  //   host: 'addnorth.no',
  //   domain: 'no',
  //   target: 'addnorth-no',
  //   paymentMethods: [
  //     'MasterCard',
  //     'Visa',
  //     'Amex',
  //     'Maestro',
  //     'Discover',
  //   ],
  //   carriers: [
  //     'UPS',
  //   ]
  // },
  {
    code: 'en',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth-com-development.web.app',
    domain: 'web.app',
    target: 'addnorth-com-development',
    languageTranslated: false,
    excludeMeta: true,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'FedEx',
      'UPS',
      'DHL',
    ]
  },
  {
    code: 'en',
    name: 'English',
    currency: 'EUR',
    showWithVat: true,
    host: 'addnorth-com-pre.web.app',
    domain: 'web.app',
    target: 'addnorth-com-pre',
    languageTranslated: false,
    excludeMeta: true,
    paymentMethods: [
      'klarna',
      'MasterCard',
      'Visa',
      'Amex',
      'Maestro',
      'Discover',
    ],
    carriers: [
      'FedEx',
      'UPS',
      'DHL',
    ]
  },
];

module.exports = {
  currencies,
  sites
};
