<template>
  <Carousel
    v-if="bestSellers.length"
    :items="bestSellers"
    :title="$tc('store.products.bestSeller', 2)"
    :viewAllLink="$store.getters['shared/encodeUrl']('shop', { sort: 'bestSellers' })"
    :viewAllText="`${$t('common.showAll')} ${$tc('store.products.bestSeller', 2).toLowerCase()}`"
    listName="Home Bestsellers"
    listId="home_bestsellers"
    key="home_bestsellers"
    type="product"
    class="my-4 mx-6 xl:mx-0"
  >
    <template v-slot:item="{ item, index, listName, listId }">
      <ProductCardDefault
        :key="item.sku"
        :product="item"
        :index="index"
        :showThumbnailBgColor="true"
        :listId="listId"
        :listName="listName"
        class="inline-block"
      />
    </template>
  </Carousel>
</template>
<script>
import Carousel from "../store/Carousel.vue";
import ProductCardDefault from "../store/ProductCardDefault.vue";
import Variation from "@/services/variation2.js";

export default {
  name: "BestSellers",
  components: {
    Carousel,
    ProductCardDefault,
  },
  computed: {
    bestSellers() {
      return this.$store.state.content.variations
        .filter((v) => v.bestSeller === 1)
        .slice(0, 6)
        .map((v) => new Variation(this.$store, v));
    },
  },
};
</script>
